import React from 'react'
import logo from '@/assets/images/logo.svg'
import styles from './styles.module.scss'

export function Header(): JSX.Element {
  return (
    <header className={styles.header}>
      <a href={process.env.REACT_APP_TOP_PAGE_URL}>
        <img src={logo} alt="uP!!!" />
      </a>

      <ul>
        <li>
          <a
            href={(process.env.REACT_APP_TOP_PAGE_URL ?? '') + '/notification/'}
          >
            お知らせ
          </a>
        </li>
        <li>
          <a href={(process.env.REACT_APP_BASE_URL ?? '') + '/mypage/'}>
            マイページ
          </a>
        </li>
      </ul>
    </header>
  )
}
