import React, { useState } from 'react'
import { Button } from '../Button'
import styles from './styles.module.scss'

export type SerialCodeProps = {
  /** クーポンコード */
  serialCode: React.MutableRefObject<string | null>
  /** クーポンコード適用 */
  serialCodeApply?: () => void
  /** クーポンコードを適用 & クーポンコードの有効・無効 */
  serialCodeStatus: { isApply: boolean; invalid: boolean }
  /** クーポンコードロック */
  serialCodeLock: boolean
  /** クーポン不正・使用済みの場合の文言 */
  couponErrorMessage: string
  setCurrentSerialCode: React.Dispatch<React.SetStateAction<string | null>>
  /** 無料視聴クーポン */
  isFreeCoupon: boolean
}

export function SerialCode({
  serialCode,
  serialCodeApply,
  serialCodeStatus,
  serialCodeLock,
  couponErrorMessage,
  setCurrentSerialCode,
  isFreeCoupon,
}: SerialCodeProps): JSX.Element {
  const [serialCodeTmp, setSerialCodeTmp] = useState(
    serialCode.current as string,
  )
  // 適用ボタンの有効・無効
  const [isInvalid, setIsInvalid] = useState(true)

  const isChangeSerialCode = (value: string) => {
    setCurrentSerialCode(value)
    setSerialCodeTmp(value)
    // 1文字以上の場合は、適用ボタンを有効にし、0文字の場合は無効にする
    setIsInvalid(!(value.length > 0))
    serialCode.current = value
  }

  // クーポンコード不正 or 適用時に表示するメッセージ
  const SerialCodeMessage = (): JSX.Element => {
    if (serialCodeStatus) {
      // クーポンコードロック時
      // eslint-disable-next-line
      if (serialCodeLock) {
        return (
          <p className={styles.message}>
            現在ロックされています。
            <br />
            しばらく経ってから再度入力ください。
          </p>
        )
      }
      // クーポンコード適用・不正時に表示するメッセージ
      // eslint-disable-next-line
      if (serialCodeStatus?.isApply) {
        return (
          <p className={styles.message}>
            <span
              dangerouslySetInnerHTML={{
                // eslint-disable-next-line
                __html: serialCodeStatus.invalid
                  ? couponErrorMessage
                  : `クーポンコードが適用されました。<br />金額をご確認ください。`,
              }}
            ></span>
          </p>
        )
      }
    }
    return <></>
  }

  // 入りきらないため、スマホとスマホ以外でレイアウトを変更する
  if (
    window.matchMedia &&
    window.matchMedia('(max-device-width: 640px)').matches
  ) {
    // スマホ
    return (
      <div className={`${styles.items} ${styles.sp} ${isFreeCoupon ? styles.freecoupon : ''}`}>
        <div className={styles.caption}>
          <p>クーポンコード</p>
        </div>
        <div className={styles.frame}>
          <div>
            <input
              type="text"
              name="serial"
              id="serial"
              value={serialCodeTmp}
              onChange={e => isChangeSerialCode(e.target.value)}
            />
          </div>
          {!isFreeCoupon && (
            <div className={`${styles.button} ${styles.applyButtonSp}`}>
              <Button
                text={'適用'}
                onClick={serialCodeApply}
                disabled={isInvalid}
              />
            </div>
          )}
        </div>
        <SerialCodeMessage />
      </div>
    )
  } else {
    // スマホ以外(PC・タブレット)
    return (
      <>
        <div className={`${styles.items} ${styles.other} ${isFreeCoupon ? styles.freecoupon : ''}`}>
          <div className={styles.caption}>
            <p>クーポンコード</p>
          </div>
          <div className={styles.frame}>
            <div>
              <input
                type="text"
                name="serial"
                id="serial"
                value={serialCodeTmp}
                onChange={e => isChangeSerialCode(e.target.value)}
              />
            </div>
            {!isFreeCoupon && (
            <div className={styles.button}>
              <Button
                text={'適用'}
                onClick={serialCodeApply}
                disabled={isInvalid}
              />
            </div>
          )}
          </div>
        </div>
        <SerialCodeMessage />
      </>
    )
  }
}
