import React, { useContext } from 'react'
import clsx from 'clsx'
import { keyObject } from '@/helpers/utils'
import styles from './styles.module.scss'
import { SharedContext } from '@/components/parts/SharedContext'
import { TicketType } from '@/enums'

/**
 * 選択した優先度とclassNameのマップ。
 */
const LinkPriorityMap = {
  primary: styles.primary,
  premium: styles.premium,
} as const

/**
 * リンクのタイプ
 */
const LinkTypeMap = {
  toNext: styles.toNext,
  reproduction: styles.reproduction,
  noIcon: styles.noIcon,
} as const

/**
 * リンク種類。
 */
export const LinkPriority = keyObject(LinkPriorityMap)
export const LinkType = keyObject(LinkTypeMap)

export type TransitionLinkProps = {
  /**
   * リンク主要度
   */
  priority?: keyof typeof LinkPriorityMap
  /**
   * リンクのタイプ
   */
  type?: keyof typeof LinkTypeMap
  /**
   * ボタンテキスト
   */
  text?: string
  /**
   * 非活性
   */
  disabled?: boolean
  /**
   * 遷移先URL
   */
  transitionUrl: string
  target?: string
  rel?: string
}

/**
 * 遷移リンク
 */
export function TransitionLink({
  priority = 'primary',
  type = 'noIcon',
  text = '',
  disabled = false,
  transitionUrl = '',
  target,
  rel,
}: TransitionLinkProps): JSX.Element {
  const sharedData = useContext(SharedContext)
  return (
    <a
      className={clsx(
        styles.transitionLink,
        LinkPriorityMap[priority],
        LinkTypeMap[type],
        {
          [styles.disabled]: disabled,
          [styles.alphaUcolor]: sharedData.siteCode === TicketType.alpha_u && !disabled,
        },
      )}
      href={transitionUrl}
      target={target}
      rel={rel}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      ></span>
    </a>
  )
}
