import React from 'react'
import logo from '@/assets/images/logo-alpha-u.svg'
import jasrac from '@/assets/images/jasrac.jpg'
import nextone from '@/assets/images/nextone.png'
import styles from './styles.module.scss'

export function AuFooter(): JSX.Element {
  return (
    <footer className={styles.footer}>
      <img src={logo} alt="αU" />
      <div className={styles.ulWrap}>
        <ul>
          <li>
            <a
              href={(process.env.REACT_APP_BASE_URL ?? '') + '/alpha-u/mypage/'}
            >
              購入履歴
            </a>
          </li>
          <li>
            <a
              href={
                (process.env.REACT_APP_TOP_PAGE_URL ?? '') + '/alpha-u/help/'
              }
            >
              ヘルプ
            </a>
          </li>
        </ul>
        <ul>
          <li>
            <a
              href={
                (process.env.REACT_APP_TOP_PAGE_URL ?? '') + '/alpha-u/term/'
              }
            >
              利用規約
            </a>
          </li>
          <li>
            <a href="https://www.kddi.com/corporate/kddi/public/privacy/">
              プライバシーポリシー
            </a>
          </li>
          <li>
            <a
              href={
                (process.env.REACT_APP_TOP_PAGE_URL ?? '') +
                '/alpha-u/about-transactions/'
              }
            >
              特定商取引法に基づく表記
            </a>
          </li>
          <li>
            <a href="https://www.kddi.com/terms/requirements/">
              Cookie情報の利用、広告配信等について
            </a>
          </li>
        </ul>
      </div>
      <p className={styles.copyright}></p>
      <div className={styles.license}>
        <img src={jasrac} />
        <div>
          <p>許諾番号</p>
          <p>9007016387Y45037</p>
          <p>9007016388Y45040</p>
        </div>
        <img src={nextone} />
        <div>
          <p>許諾番号</p>
          <p>ID000008921</p>
          <p>ID000008922</p>
        </div>
      </div>
    </footer>
  )
}
