import React from 'react'
import Vimeo from '@u-wave/react-vimeo'
import styles from './styles.module.scss'

export type VimeoPlayerProps = {
  id: string
}

export const VimeoPlayer: React.FC<VimeoPlayerProps> = ({ id = 0 }) => {
  return (
    <div className={styles.playerWrapper}>
      <Vimeo video={id} autoplay />
    </div>
  )
}
