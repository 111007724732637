const phoneNumberRegex = /^[0-9０-９]+$/
const emailRegex = /^[A-Za-z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Za-z0-9-]+(?:\.[A-Za-z0-9-]+)+$/

export const Regex = {
  isPhoneNumber: (s: string): boolean => {
    return phoneNumberRegex.test(s)
  },
  isEmail: (s: string): boolean => {
    return emailRegex.test(s)
  },
}
