import React from 'react'
import styles from './styles.module.scss'

export type LoaderProps = {
  isShown: boolean
}

/**
 * アクションボタン
 */
export function Loader({ isShown }: LoaderProps): JSX.Element {
  if (isShown) {
    return (
      <div className={styles.LoaderWrap}>
        <div className={styles.loader}></div>
      </div>
    )
  }
  return <></>
}
