import React, { useCallback, useState } from 'react'
import styles from './styles.module.scss'
import { OptionPrice, Ticket } from '@/models/ticket'
import { useLocation, useNavigate } from 'react-router-dom'
import back from '@/assets/icon/icn-back.svg'
import { Button } from '@/components/parts/Button'
import { ListTicketAmount, ListType } from '@/components/parts/ListTicketAmount'
import { API } from '@/network/api-client'
import dayjs from 'dayjs'
import { TicketType, SitePath } from '@/enums'

/** 個人情報の型定義 */
interface personalInfoInterface {
  name: string /** 名前 */
  tel: string /** 電話番号 */
  mail: string /** メールアドレス */
  postalCode: string /** 郵便番号 */
  address: string /** 住所 */
}

/** コンビニ決済用の個人情報型定義 */
interface PaymentInfoInterface {
  name: string /** 名前(コンビニ決済用) */
  mail: string /** メールアドレス(コンビニ決済用) */
}

/** コンビニ決済用の個人情報バリデーション型定義 */
interface PaymentInfoValidationInterface {
  nameError: string | null /** 名前のバリデーションエラー */
  mailError: string | null /** メールアドレスのバリデーションエラー */
}

export function Payment(): JSX.Element {
  const navigate = useNavigate()
  const ticketData = useLocation().state as {
    ticketGroupId: number
    ticket: Ticket
    options: OptionPrice[]
    articleId: string
    isDirect: boolean
    siteCode: number
    serialCode: string | null /** 入力したシリアルコード */
    pid: number /** PriceレコードのID */
    isMembership: boolean // 会員コンテンツか
    generalPrice?: number // 一般価格
    membershipPrice?: number // 会員価格
    optionTotal: number /** システム手数料計 */
    discountTitle: string /** 割引名 */
    discountPrice: number /** 割引金額 */
    formName: string /** お名前 */
    formTel: string /** 電話番号 */
    formMail: string /** メールアドレス */
    formConfirmationMail: string /** メールアドレス(確認) */
    formPostalCode: string /** 郵便番号 */
    formAddress: string /** 住所 */
    name: string /** 名前(コンビニ決済用) */
    mail: string /** メールアドレス(コンビニ決済用) */
    backPageUrl: string | null /** 前画面のURL */
    promotionMail?: boolean | null // メールマガジン
    freeCoupon: number /** 無料視聴クーポン */
  }

  // 送信する個人情報
  const personalInfo: personalInfoInterface = {
    name: ticketData.formName /** 名前 */,
    tel: ticketData.formTel /** 電話番号 */,
    mail: ticketData.formMail /** メールアドレス */,
    postalCode: ticketData.formPostalCode /** 郵便番号 */,
    address: ticketData.formAddress /** 住所 */,
  }

  // コンビニ決済可能時間(30分)
  const CONVENIENCE_PAYMENT_LIMIT_TIME = 30 * 60

  const errPageUrl =
    (process.env.REACT_APP_BASE_URL ?? '') +
    SitePath(ticketData.siteCode) +
    `/purchase/error/${ticketData.articleId}`

  const errHandler = useCallback(() => {
    window.open(errPageUrl, '_parent', 'noreferrer')
  }, [errPageUrl])

  // コンビニ決済が使用できるかチェック
  const isCanConveniencePayment = (): boolean => {
    // 販売終了時間
    const saleEnd = dayjs(ticketData.ticket.saleEnd)
    // 現在時刻と販売終了時間の差が指定時間未満の場合は、コンビニ決済が出来ない
    return saleEnd.diff(dayjs()) / 1000 < CONVENIENCE_PAYMENT_LIMIT_TIME
  }

  const backPage = () => {
    // 遷移先URL
    const url = ticketData.backPageUrl ?? '/purchase/personal-data'
    navigate(url, {
      state: {
        ticketGroupId: ticketData.ticketGroupId,
        ticket: ticketData.ticket,
        options: ticketData.options,
        articleId: ticketData.articleId,
        isDirect: ticketData.isDirect,
        siteCode: ticketData.siteCode,
        serialCode: ticketData.serialCode, // 入力したシリアルコード
        discountTitle: ticketData.discountTitle, // 割引名
        discountPrice: ticketData.discountPrice, // 割引金額
        formName: ticketData.formName, //  お名前
        formTel: ticketData.formTel, // 電話番号
        formMail: ticketData.formMail, // メールアドレス
        formConfirmationMail: ticketData.formConfirmationMail, // メールアドレス(確認)
        formPostalCode: ticketData.formPostalCode, // 郵便番号
        formAddress: ticketData.formAddress, // 住所
        isMembership: ticketData.isMembership /** 会員コンテンツか */,
        generalPrice: ticketData.generalPrice /** 一般価格 */,
        membershipPrice: ticketData.membershipPrice /** 会員価格 */,
        optionTotal: ticketData.optionTotal /** システム手数料計 */,
        pid: ticketData.pid /** PriceレコードのID */,
        promotionMail: ticketData.promotionMail, // メールマガジン
        freeCoupon: ticketData.freeCoupon /** 無料視聴クーポン */,
      },
    })
  }

  // コンビニ決済が選択されているか
  const [choiceCvs, setChoiceCvs] = useState(false)

  // 支払いのステータス
  const PaymentStatus = {
    Initial: 0,
    Applying: 1,
    Complate: 2,
  }
  const [paymentStatus, setPaymentStatus] = useState(PaymentStatus.Initial)
  const [clickPurchase, setClickPurchase] = useState(false)
  let duplicateApiCall = false

  // かんたん決済
  const purchaseButtonClicked = useCallback(
    (ticketId: number, priceId: number, commodity: string) => {
      // 簡単決済のシステムでトランザクションIDを発行したのち、システム側のページに遷移させる
      const ticketAuthory = async () => {
        const completePageUrl =
          (process.env.REACT_APP_BASE_URL ?? '') +
          SitePath(ticketData.siteCode) +
          '/purchase/complete'

        // 個人情報入力を通らなかった場合個人情報にはnullを渡す
        const sendPersonalInfo =
          personalInfo.name === undefined || personalInfo.name === ''
            ? null
            : personalInfo

        const res = await API.purhcaseTicketAuthory(
          ticketId,
          priceId,
          commodity,
          completePageUrl,
          errPageUrl,
          Number(ticketData.articleId),
          // クーポンコード
          ticketData.serialCode,
          // 名前（コンビニ決済用）
          ticketData.name,
          // メールアドレス（コンビニ決済用）
          ticketData.mail,
          // 個人情報
          sendPersonalInfo,
          // メールマガジン
          ticketData.promotionMail,
        )

        if (!res.success) {
          errHandler()
          return
        }

        window.open(res.result.redirectUrl, '_parent', 'noreferrer')
      }

      ticketAuthory().catch(() => {
        errHandler()
      })
    },
    [ticketData, errHandler, errPageUrl, personalInfo],
  )
  // コンビニ決済
  const convenienceStorePayment = useCallback(
    (ticketId: number, pid: number) => {
      // 現在時刻と販売終了時間の差が指定時間未満の場合は、早期リターン
      if (isCanConveniencePayment() || duplicateApiCall) {
        return
      }

      // 個人情報入力を通らなかった場合個人情報にはnullを渡す
      const sendPersonalInfo =
        personalInfo.name === undefined || personalInfo.name === ''
          ? null
          : personalInfo

      // 申し込み中画面を表示
      setPaymentStatus(PaymentStatus.Applying)

      // 購入ボタン、コンビニ決済２重呼び出しを抑止
      setClickPurchase(true)
      duplicateApiCall = true

      const stripeAuthory = async () => {
        const res = await API.purhcaseStripeAuthory(
          // チケットID
          ticketId,
          // 記事ID
          Number(ticketData.articleId),
          // PriceレコードのID
          pid,
          // クーポンコード
          ticketData.serialCode,
          // 名前(コンビニ決済用)
          ticketData.name,
          // メールアドレス(コンビニ決済用)
          ticketData.mail,
          // 個人情報
          sendPersonalInfo,
          // メールマガジン
          ticketData.promotionMail,
        )
        if (!res.success) {
          errHandler()
          return
        }

        // コンビニ決済が完了した場合は決済完了
        setPaymentStatus(PaymentStatus.Complate)

        window.open(res.result.redirectURL, '_parent', 'noreferrer')
      }

      stripeAuthory().catch(() => {
        errHandler()
      })
    },
    [personalInfo, errHandler],
  )

  // 必須入力チェック
  const mandatoryValidation = (val: string | null) => !val
  // 入力内容チェック
  const checkValidation = (val: string | null, regex: RegExp) =>
    !val?.match(regex)

  /** コンビニ決済での注意事項 */
  const KonbiniAttention = () => {
    // auIDポータルのURL
    const auIdPortalUrl = 'https://id.auone.jp/'
    return (
      <div>
        {/* 文言 */}
        <div className={styles.attentionSection}>
          <p>
            決済完了後のメールはau IDに紐づいたメールに送ります。
            <br />
            au
            IDにご登録いただいているメールアドレスに送信許可がされていない場合、届かない場合がございます。
          </p>
          <p>
            <a href={auIdPortalUrl} target="_blank" rel="noopener noreferrer">
              こちらのau IDポータル
            </a>
            より事前のご確認をお願いいたします。
          </p>
        </div>
        {/* 注意事項 */}
        <p className={styles.attentiontext}>注意事項</p>
        <div className={styles.attentionSection}>
          <ul>
            <li className={styles.attentionCircle}>
              コンビニ支払いは外部決済代行業者を使用します。
            </li>
            <li className={styles.attentionCircle}>
              コンビニ支払いをお申し込みの場合、別途、コンビニ支払い手数料が発生します。
            </li>
            <li className={styles.attentionCircle}>
              コンビニ支払いの対応店舗は「 ローソン / ファミリーマート /
              ミニストップ / セイコーマート 」の4社となります。
            </li>
          </ul>
        </div>
      </div>
    )
  }

  const Contents = () => {
    // コンビニ決済手数料
    const commission: number = ticketData.ticket?.konbiniCommission ?? 0

    // 入力フォーム(コンビニ決済用)
    const [form, setForm] = useState<PaymentInfoInterface>({
      name: ticketData.name, // 名前(コンビニ決済用)
      mail: ticketData.mail, // メールアドレス(コンビニ決済用)
    })

    // 合計金額(会員コンテンツの場合は、会員価格(取得できない場合は0円) + 手数料計 + コンビニ決済が選択されていればコンビニ決済手数料)
    // (会員コンテンツ以外は、一般価格(取得できない場合は0円) + 手数料計 + コンビニ決済が選択されていればコンビニ決済手数料)
    const totalPrice =
      (ticketData.isMembership
        ? (ticketData.membershipPrice ? ticketData.membershipPrice : 0) +
          ticketData.optionTotal
        : (ticketData.generalPrice ? ticketData.generalPrice : 0) +
          ticketData.optionTotal) + (choiceCvs ? commission : 0)

    // 最大支払い金額
    // 合計金額(会員コンテンツの場合は、会員価格(取得できない場合は0円) + 手数料計 + コンビニ決済手数料 - 割引額)
    // (会員コンテンツ以外は、一般価格(取得できない場合は0円) + 手数料計 + コンビニ決済手数料 - 割引額)
    const contentsMaxPrice =
      (ticketData.isMembership
        ? (ticketData.membershipPrice ? ticketData.membershipPrice : 0) +
          ticketData.optionTotal +
          commission -
          ticketData.discountPrice
        : (ticketData.generalPrice ? ticketData.generalPrice : 0) +
          ticketData.optionTotal) +
      commission -
      ticketData.discountPrice

    // 会員種別
    const isTelasa = ticketData.siteCode === TicketType.telasa

    // 入力フォームのバリデーションエラー
    const [validationError, setValidationError] =
      useState<PaymentInfoValidationInterface>({
        nameError: null, // 名前エラー
        mailError: null, // メールアドレスエラー
      })

    // 名前のバリデーション
    const nameValidation = () => {
      // 名前未入力
      if (mandatoryValidation(ticketData.name)) {
        setValidationError({
          ...validationError,
          nameError: '※ お名前の入力は必須です。',
        })
      } else {
        setValidationError({ ...validationError, nameError: null })
        return true
      }
      return false
    }

    // メールアドレスのバリデーション
    const mailValidation = () => {
      // eslint-disable-next-line
      const mailRegex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

      // メールアドレス未入力
      if (mandatoryValidation(ticketData.mail)) {
        setValidationError({
          ...validationError,
          mailError: '※ メールアドレスの入力は必須です。',
        })
      } else if (checkValidation(ticketData.mail, mailRegex)) {
        // メールアドレス形式エラー
        setValidationError({
          ...validationError,
          mailError: '※ 入力内容が正しくありません。',
        })
      } else {
        setValidationError({ ...validationError, mailError: null })
        return true
      }
      return false
    }

    const myPageUrl =
      ticketData.siteCode === TicketType.alpha_u
        ? (process.env.REACT_APP_BASE_URL ?? '') + '/alpha-u/mypage'
        : ticketData.siteCode === TicketType.telasa
        ? (process.env.REACT_APP_BASE_URL ?? '') + '/telasa/mypage'
        : (process.env.REACT_APP_BASE_URL ?? '') + '/mypage'

    const myPageTitle =
      ticketData.siteCode === TicketType.up ? 'マイページ' : '購入履歴'

    return (
      <>
        {/* 価格表示 */}
        <div>
          <div className={styles.amountlistWrap}>
            {/* 一般価格が存在する場合 */}
            {ticketData.generalPrice !== undefined && (
              <ListTicketAmount
                // Pontaパス会員でログインし、一般価格が存在する場合は、打消し線を表示する
                type={
                  ticketData.isMembership
                    ? ListType.linethrough
                    : ListType.ticket
                }
                text={ticketData.ticket.title}
                amount={ticketData.generalPrice ? ticketData.generalPrice : 0}
              />
            )}
            {/* 会員価格 */}
            {ticketData.isMembership &&
              ticketData.membershipPrice !== undefined && (
                <ListTicketAmount
                  type={ListType.ticket}
                  text={
                    ticketData.ticket.title +
                    '\n' +
                    (isTelasa
                      ? '(見放題プラン加入者価格)'
                      : '(Pontaパス会員価格)')
                  }
                  amount={
                    ticketData.membershipPrice ? ticketData.membershipPrice : 0
                  }
                />
              )}
            {/* システム手数料 */}
            {ticketData.options.map(option => (
              <ListTicketAmount
                key={option.id}
                type={ListType.general}
                text={option.optionName}
                amount={option.optionPrice}
              />
            ))}
            {/* コンビニ決済手数料 (コンビニ決済が選択された場合に表示) */}
            {choiceCvs && (
              <ListTicketAmount
                type={ListType.general}
                text={`コンビニ支払い手数料`}
                amount={commission}
              />
            )}
            {/* クーポン割引が存在する場合 */}
            {ticketData.discountPrice > 0 && (
              <ListTicketAmount
                type={ListType.general}
                text={ticketData.discountTitle}
                amount={-ticketData.discountPrice}
              />
            )}
            {/* 合計金額 */}
            <ListTicketAmount
              type={isTelasa ? ListType.telasaTotal : ListType.total}
              text="合計（税込）"
              amount={
                // 合計金額が0未満にならないようにする
                totalPrice - ticketData.discountPrice < 0
                  ? 0
                  : totalPrice - ticketData.discountPrice
              }
            />
          </div>
        </div>

        {/* コンビニ手数料を含んだ支払い金額が0円より大きい場合支払い選択画面を表示 */}
        {contentsMaxPrice > 0 && (
          <div className={styles.amountlistWrap}>
            <p className={styles.paymentTitle}>お支払方法の選択</p>

            {/* auかんたん決済 */}
            <div>
              <label className={styles.paymentSelect}>
                <div className={styles.paymentRadioButton}>
                  <input
                    type="radio"
                    checked={choiceCvs === false}
                    // コンビニ決済フラグfalse
                    onChange={e => setChoiceCvs(!e.target.checked)}
                    disabled={paymentStatus !== PaymentStatus.Initial}
                  />
                  {/* ラジオボタン(表示用) */}
                  <div
                    className={`${styles.radioButton} 
                    ${
                      !choiceCvs
                        ? ticketData.siteCode === TicketType.alpha_u
                          ? styles.radioButtonSelectedAu
                          : styles.radioButtonSelected
                        : styles.radioButton
                    }
                    `}
                  ></div>
                </div>
                <div className={styles.paymentExplanation}>
                  <p className={styles.paymentExplanationTitle}>
                    au PAY 残高支払い
                  </p>
                  <p className={styles.paymentExplanationTitle}>
                    au通信料への合算請求
                  </p>
                  <p className={styles.paymentExplanationTitle}>
                    クレジットカード
                  </p>
                  <div className={styles.paymentExplanationText}>
                    <small>au かんたん決済を使用して決済します。</small>
                    <small>
                      ※auにご契約されていないお客様もご利用いただけます。
                    </small>
                  </div>
                </div>
              </label>
            </div>

            {/* コンビニ決済 */}
            <div>
              <label
                className={`${styles.paymentSelect} ${
                  isCanConveniencePayment() ? styles.notPaymentSelect : ''
                }`}
              >
                <div className={styles.paymentRadioButton}>
                  <input
                    type="radio"
                    checked={choiceCvs === true}
                    // コンビニ決済フラグtrue
                    onChange={e => setChoiceCvs(e.target.checked)}
                    disabled={isCanConveniencePayment()}
                  />
                  {/* ラジオボタン(表示用) */}
                  <div
                    className={`${styles.radioButton} ${
                      choiceCvs
                        ? styles.radioButtonSelected
                        : styles.radioButton
                    }`}
                  ></div>
                </div>
                <div className={styles.paymentExplanation}>
                  <p className={styles.paymentExplanationTitle}>
                    コンビニ支払い<small>（手数料{commission}円）</small>
                  </p>
                  <div className={styles.paymentExplanationText}>
                    <small>対応店舗</small>
                    <small>
                      ローソン / ファミリーマート / ミニストップ /
                      セイコーマート
                    </small>
                  </div>
                </div>
              </label>
            </div>

            {/* auかんたん決済を選択した場合 */}
            {!choiceCvs && (
              <div className={styles.linkWrap}>
                <Button
                  text={'決済画面へ進む'}
                  type="toNext"
                  disabled={clickPurchase}
                  onClick={() => {
                    purchaseButtonClicked(
                      // チケットID
                      ticketData.ticket.id,
                      // PriceレコードのID
                      ticketData.pid,
                      // 適用
                      ticketData.ticket.title,
                    )
                  }}
                />
              </div>
            )}
          </div>
        )}

        {contentsMaxPrice <= 0 && (
          <div className={styles.linkWrap}>
            <Button
              text={'購入する'}
              type="toNext"
              disabled={clickPurchase}
              onClick={() => {
                purchaseButtonClicked(
                  // チケットID
                  ticketData.ticket.id,
                  // PriceレコードのID
                  ticketData.pid,
                  // 適用
                  ticketData.ticket.title,
                )
              }}
            />
          </div>
        )}

        {/* コンビニ決済を選択した場合 */}
        {choiceCvs && (
          <>
            <p className={styles.paymentTitle}>お申込み内容</p>
            <div className={styles.convenienceData}>
              {/* お名前 */}
              <p className={styles.convenienceDataLabel}>
                <strong>必須</strong>お名前
              </p>
              <div>
                <input
                  className={`${styles.convenienceDataInput} ${
                    validationError.nameError ? styles.error : ''
                  }`}
                  type="text"
                  name="name"
                  maxLength={50}
                  placeholder="山田　たかし"
                  value={form.name}
                  onChange={e => {
                    setForm({ ...form, name: e.target.value.trimStart() })
                    ticketData.name = e.target.value.trimStart()
                  }}
                  onBlur={() => nameValidation()}
                  defaultValue={ticketData.name}
                  disabled={paymentStatus !== PaymentStatus.Initial}
                />
                {/* バリデーションエラー文言 */}
                <small className={styles.validationErrorText}>
                  {validationError.nameError}
                </small>
              </div>
              {/* メールアドレス */}
              <p className={styles.convenienceDataLabel}>
                <strong>必須</strong>メールアドレス
              </p>
              <div>
                <input
                  className={`${styles.convenienceDataInput} ${
                    validationError.mailError ? styles.error : ''
                  }`}
                  name="mail"
                  maxLength={256}
                  placeholder="sample@example.com"
                  value={form.mail}
                  onChange={e => {
                    setForm({ ...form, mail: e.target.value.trimStart() })
                    ticketData.mail = e.target.value.trimStart()
                  }}
                  onBlur={() => mailValidation()}
                  defaultValue={ticketData.mail}
                  disabled={paymentStatus !== PaymentStatus.Initial}
                />
                {/* バリデーションエラー文言 */}
                <small className={styles.validationErrorText}>
                  {validationError.mailError}
                </small>
              </div>
            </div>
            {/* 注意事項 */}
            <KonbiniAttention />
            <div className={styles.linkWrap}>
              {paymentStatus === PaymentStatus.Initial && (
                <Button
                  text={'決済画面へ進む'}
                  type="toNext"
                  // どれかにエラーが表示されている or 値が未入力 or コンビニ決済が出来ない場合は非活性
                  disabled={
                    !form.name ||
                    !!validationError.nameError ||
                    !form.mail ||
                    !!validationError.mailError ||
                    isCanConveniencePayment() ||
                    clickPurchase
                  }
                  onClick={() => {
                    convenienceStorePayment(
                      // チケットID
                      ticketData.ticket.id,
                      // PriceレコードのID
                      ticketData.pid,
                    )
                  }}
                />
              )}
              {paymentStatus === PaymentStatus.Applying && (
                <p>コンビニ支払い申し込み中</p>
              )}
              {paymentStatus === PaymentStatus.Complate && (
                <p>
                  コンビニお支払い申し込み済みです。
                  <br />
                  <a href={myPageUrl} target="_blank" rel="noopener noreferrer">
                    {myPageTitle}
                  </a>
                  をご確認ください。
                </p>
              )}
            </div>
          </>
        )}
      </>
    )
  }

  return (
    <div className={styles.pageWrap}>
      <header className={styles.header}>
        {!ticketData.isDirect && (
          <button className={styles.toBack} onClick={() => backPage()}>
            <img src={back} />
          </button>
        )}
        <h1 className={styles.title}>チケットの購入</h1>
      </header>
      <div className={styles.mainContents} id="contentsAreaPayment">
        <Contents />
      </div>
    </div>
  )
}
