import React from 'react'
import logo from '@/assets/images/logo-telasa.svg'
import jasrac from '@/assets/images/jasrac.jpg'
import nextone from '@/assets/images/nextone.png'
import styles from './styles.module.scss'

export function TelasaFooter(): JSX.Element {
  return (
    <footer className={styles.footer}>
      <img src={logo} alt="TELASA" />
      <div className={styles.ulWrap}>
        <ul>
          <li>
            <a
              href={
                (process.env.REACT_APP_TOP_PAGE_URL ?? '') + '/telasa/help/'
              }
            >
              ヘルプ
            </a>
          </li>
          <li>
            <a href="https://help.telasa.jp/user-policy/">利用規約</a>
          </li>
          <li>
            <a href="https://help.telasa.jp/privacy/">プライバシーポリシー</a>
          </li>
          <li>
            <a href="https://help.telasa.jp/faq/transaction/">
              特定商取引法に基づく表記
            </a>
          </li>
        </ul>
      </div>
      <div className={styles.copyright}>
        <p>© TELASA CORPORATION, All Rights Reserved.</p>
      </div>
      <div className={styles.license}>
        <img src={jasrac} />
        <div>
          <p>許諾番号</p>
          <p>9007016380Y45037</p>
        </div>
        <img src={nextone} />
        <div>
          <p>許諾番号</p>
          <p>ID000007375</p>
        </div>
      </div>
    </footer>
  )
}
