import React, { useState, useEffect, useContext } from 'react'
import { noOperation, generateRandomNumber } from '@/helpers/utils'
import { Regex } from '@/helpers/regex'
import clsx from 'clsx'
import styles from './styles.module.scss'
import { SharedContext } from '@/components/parts/SharedContext'
import { TicketType } from '@/enums'

export type KKSModalProps = {
  login: (name: string) => void
}

export const KKSModal: React.FC<KKSModalProps> = ({ login = noOperation }) => {
  // 初期表示は GuestXXXXX とする
  const [name, setName] = useState('Guest' + generateRandomNumber(5))
  const [isLoginButtonDisabled, setIsLoginButtonDisabled] = useState(true)
  const [isInvalidName, setIsInvalidName] = useState(false)

  const sharedData = useContext(SharedContext)

  useEffect(() => {
    setIsLoginButtonDisabled(!name)
  }, [name])

  function loginButtonDidTap(name: string) {
    if (Regex.isPhoneNumber(name) || Regex.isEmail(name)) {
      setIsInvalidName(true)
      return
    }

    setIsInvalidName(false)
    login(name)
  }

  return (
    <div className={clsx(styles.overlay)}>
      <div className={clsx(styles.modarlWrapper)}>
        <div className={clsx(styles.contents)}>
          <p className={clsx(styles.headline)}>名前を入力</p>
          <p className={clsx(styles.text)}>
            本配信ではチャット機能をご利用いただけます。
            <br />
            配信画面に進む前にお名前（ニックネーム）を入力してください。
          </p>

          <p className={clsx(styles.notice)}>
            ※一度決定した名前を変更したい場合は、マイページ等から視聴手続きをやり直してください
          </p>
          <p className={clsx(styles.notice)}>
            ※名前には、個人情報(電話番号、Eメールアドレスなど)を登録しないでください。
          </p>
          <p className={clsx(styles.notice)}>
            ※個人情報を含む内容を確認した場合、また、不適切な発言を確認した場合はチャット機能の利用を停止することがあります。
          </p>

          <input
            className={clsx(styles.nameInput)}
            value={name}
            onChange={e => setName(e.target.value)}
            type="text"
            placeholder="名前を入力"
          />
          {isInvalidName && (
            <p className={clsx(styles.alertText)}>
              入力されたお名前はご利用になれません
            </p>
          )}

          <button
            className={clsx(styles.loginButton, {
              [styles.alphaUcolor]: sharedData.siteCode === TicketType.alpha_u && !isLoginButtonDisabled,
            })}
            disabled={isLoginButtonDisabled}
            onClick={() => loginButtonDidTap(name)}
          >
            名前を決定して配信画面へ進む
          </button>
        </div>
      </div>
    </div>
  )
}
