import React, { useCallback } from 'react'
import { Header } from '@/components/parts/Header'
import { Footer } from '@/components/parts/Footer'
import { AuHeader } from '@/components/parts/alpha-u/Header'
import { AuFooter } from '@/components/parts/alpha-u/Footer'
import styles from './styles.module.scss'
import { Button } from '@/components/parts/Button'
import { TicketType, SitePath } from '@/enums'

// propsとして受け取る型を定義
type Props = {
  site?: string
}

export function Login(props: Props): JSX.Element {
  // siteCode 0:uP!!! 1:TELASA 2:alpha-u
  const siteCode: number =
    props.site === 'alpha-u'
      ? TicketType.alpha_u
      : props.site === 'telasa'
      ? TicketType.telasa
      : TicketType.up

  const login = useCallback(() => {
    const targetUrl = encodeURIComponent(
      (process.env.REACT_APP_BASE_URL ?? '') + SitePath(siteCode) + '/mypage',
    )
    const url =
      (process.env.REACT_APP_COCOA_LOGIN_URL ?? '') + `?targeturl=${targetUrl}`
    window.location.href = url
  }, [])

  const register = useCallback(() => {
    const targetUrl = encodeURIComponent(
      (process.env.REACT_APP_BASE_URL ?? '') + SitePath(siteCode) + '/mypage',
    )
    const url =
      (process.env.REACT_APP_COCOA_REGISTER_URL ?? '') +
      `?ctid=idregist&targeturl=${targetUrl}`
    window.location.href = url
  }, [])

  return (
    <div
      className={`${
        siteCode === TicketType.alpha_u
          ? styles.pageWrapperAu
          : siteCode === TicketType.telasa
          ? styles.pageWrapperTelasa
          : styles.pageWrapperUp
      }`}
    >
      {siteCode === TicketType.up && <Header />}
      {siteCode === TicketType.alpha_u && <AuHeader />}
      <div className={styles.contentsWrap}>
        <div className={styles.header}>
          <p>ログイン/新規登録</p>
        </div>
        <div className={styles.auid}>
          <p>au IDをお持ちの方</p>
          <div className={styles.button}>
            <Button text="au IDでログイン" onClick={() => login()} />
          </div>
        </div>
        <div className={styles.auid}>
          <p>au IDをお持ちでない方</p>
          <div className={styles.button}>
            <Button text="au IDを新規登録" onClick={() => register()} />
          </div>
        </div>
        <div className={styles.border} />
        <div className={styles.description}>
          <p className={styles.title}>au IDとは？</p>
          <p className={styles.text}>
            auの各種サービスやコンテンツを利用になるための、専用のIDです。au回線以外のお客様も無料で登録いただけます。
          </p>
        </div>
      </div>
      {siteCode === TicketType.up && <Footer />}
      {siteCode === TicketType.alpha_u && <AuFooter />}
    </div>
  )
}
