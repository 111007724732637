import React from 'react'
import logo from '@/assets/images/logo-telasa.svg'
import styles from './styles.module.scss'

export function TelasaHeader(): JSX.Element {
  return (
    <header className={styles.telasaHeader}>
      <a href={process.env.REACT_APP_TELASA_URL}>
        <img src={logo} />
      </a>

      <a href={(process.env.REACT_APP_BASE_URL ?? '') + '/telasa/mypage'}>
        購入履歴
      </a>
    </header>
  )
}

export function TelasaHeaderNoLink(): JSX.Element {
  return (
    <header className={styles.telasaHeader}>
      <img src={logo} />

      <a href={(process.env.REACT_APP_BASE_URL ?? '') + '/telasa/mypage'}>
        購入履歴
      </a>
    </header>
  )
}
