import React, { FC, ReactNode } from 'react'
import { useProvideModal, ModalContext } from '@/common-hooks/useModal'
import clsx from 'clsx'
import styles from './styles.module.scss'

/**
 * Modalのタイプ
 */

export const ModalType = {
  onClose: 'onClose',
  toBack: 'toBack',
}

export type ModalProps = {
  modalContent: ReactNode
  onClose?: () => void
  modalTitle?: string
  modalType?: string
  className?: string
}

export const Modal: FC<ModalProps> = ({
  modalContent,
  onClose,
  modalTitle,
  modalType,
  className,
}) => {
  if (modalType === 'onClose') {
    return (
      <div className="modal">
        <div className={clsx(styles.overlay)} onClick={onClose}></div>
        <div className={clsx(styles.modalWrapper)}>
          <div className={`${styles.modalHeader} ${className as string}`}>
            <p>{modalTitle}</p>
            <button className={styles.closeButton} onClick={onClose} />
          </div>
          {modalContent}
        </div>
      </div>
    )
  } else if (modalType === 'toBack') {
    return (
      <div className="modal">
        <div className={clsx(styles.overlay)} onClick={onClose}></div>
        <div className={clsx(styles.modalWrapper)}>
          <div className={styles.modalHeader}>
            <button className={styles.backButton} />
            <p>{modalTitle}</p>
          </div>
          {modalContent}
        </div>
      </div>
    )
  } else if (modalType === 'personalInfo') {
    // マイページの送付先情報確認
    return (
      <div className="modal">
        <div className={clsx(styles.overlay)} onClick={onClose}></div>
        <div className={clsx(styles.modalWrapper)} id="modalShowScroll">
          <div className={`${styles.modalHeader} ${className as string}`}>
            <p>{modalTitle}</p>
            <button className={styles.closeButton} onClick={onClose} />
          </div>
          {modalContent}
        </div>
      </div>
    )
  }
  return (
    <div className="modal">
      <div className={clsx(styles.overlay)} onClick={onClose}></div>
      <div className={clsx(styles.modalWrapper)}>
        <div className={styles.modalHeader}>
          <p>{modalTitle}</p>
        </div>
        {modalContent}
      </div>
    </div>
  )
}

export type ModalProviderProps = {
  modalTitle?: string
  modalType?: string
  className?: string
}

export const ModalProvider: FC<ModalProviderProps> = ({
  children,
  modalTitle,
  modalType = 'toBack',
  className,
}) => {
  const {
    isOpen,
    setIsOpen,
    modalContent,
    setModalContent,
    openModal,
    closeModal,
  } = useProvideModal()

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        setIsOpen,
        modalContent,
        setModalContent,
        openModal,
        closeModal,
      }}
    >
      {isOpen && (
        <Modal
          modalContent={modalContent}
          onClose={closeModal}
          modalTitle={modalTitle}
          modalType={modalType}
          className={className}
        />
      )}
      {children}
    </ModalContext.Provider>
  )
}
