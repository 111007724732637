import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import styles from './styles.module.scss'
import { Button } from '@/components/parts/Button'
import './style.css'

export function PageAnnounce(): JSX.Element {
  const { articleId } = useParams()
  const login = useCallback(() => {
    const targetUrl = encodeURIComponent(
      (process.env.REACT_APP_TOP_PAGE_URL ?? '') +
        `/telasa/live/${String(articleId)}/?login=true`,
    )
    const url =
      (process.env.REACT_APP_COCOA_LOGIN_URL ?? '') + `?targeturl=${targetUrl}`
    if (window.top != null) {
      window.top.location.href = url
    } else {
      window.location.href = url
    }
  }, [])

  return (
    <div className={styles.pageWrap}>
      <header className={styles.header}>
        <h1 className={styles.title}>有料ライブ配信の購入方法</h1>
      </header>
      <div id="contentAnnounce" className="contentArea">
        <div className={styles.contentTop}>
          有料ライブ配信のご利用にはau IDが必要です。
          <br />
          au IDをお持ちでないお客さまは、新規登録後にご利用いただけます。
        </div>
        <div className={styles.contentMiddle}>
          <Button text="ログイン/新規登録" onClick={() => login()} />
        </div>
        <div className={styles.contentBottom}>
          <ul>
            <li>au回線以外の方もご利用いただけます。</li>
            <li>
              Pontaパス会員の方は、会員登録済みのau
              IDで上記よりログインしてください。
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
