import React, {
  ReactNode,
  useContext,
  useCallback,
  useMemo,
  useRef,
  FC,
} from 'react'
import clsx from 'clsx'
import equal from 'fast-deep-equal'
import { RadioGroupContext } from '@/components/parts/RadioGroup'
import { useCheck, CheckState, CheckContext } from '@/common-hooks/check'
import styles from './styles.module.scss'
import { SharedContext} from '@/components/parts/SharedContext'
import { TicketType } from '@/enums'

/**
 * ## デフォルト・チェックボックスレンダラ
 *
 * 純粋な見た目のみを提供する。
 * disabled, checked, active, hover, focusedといった状態を受け取り、それぞれの状態に合わせた見た目を構成する。
 * これらの見た目に関する状態は、Radioコンポーネントにより付与される。
 */
export const DefaultRadioRender = (props: CheckState): JSX.Element => {
  const { active, disabled, focused, hover, checked } = props
  const sharedData = useContext(SharedContext)
  const radioClassName = clsx(styles.radio, {
    [styles['radio-checked']]: checked && sharedData.siteCode !== TicketType.alpha_u,
    [styles['radio-checked-au']]: checked && sharedData.siteCode === TicketType.alpha_u,
    [styles['radio-active']]: active && !disabled,
    [styles['radio-disabled']]: disabled,
    [styles['radio-focused']]: focused && !disabled,
    [styles['radio-hover']]: hover && !disabled,
  })
  return <div data-testid="DefaultRadioRender" className={radioClassName} />
}

type RadioProps<T> = {
  /**
   * チェックボックスのvalue。
   */
  value: T
  /**
   * radioをdisabled状態にする。
   */
  disabled?: boolean
  /**
   * チェックボックスがcheckedの場合のテキスト。
   */
  textChecked?: string
  /**
   * チェックボックスがnotCheckedの場合のテキスト。
   */
  textNotChecked?: string
  /**
   * radioのデフォルトチェック状態。
   */
  defaultChecked?: boolean
  /**
   * ラベル
   */
  label?: string
  /**
   * ダークモード
   */
  dark?: boolean
  /**
   * チェックボックス内に配置する、チェックボックス要素以外を、childrenとして持たせることができる。
   */
  children?: ReactNode
  /**
   * チェックボックス内に配置する、チェックボックスの見た目を描画するためのコンポーネントをセット可能。
   * 指定しなければDefaultRadioRenderが使用される。
   */
  render?: FC<CheckState>
}

/**
 * ## チェックボックス
 *
 * 基本となるチェックボックス実装。
 * childrenに子要素を渡すことで、子要素ごとまとめてクリック領域に含める。
 */
export function Radio<T>({
  value,
  disabled = false,
  children,
  defaultChecked,
  label,
  dark = false,
  render = DefaultRadioRender,
}: RadioProps<T>): JSX.Element {
  const radioGroupContext = useContext(RadioGroupContext)
  const sharedData = useContext(SharedContext)
  const checked = useMemo(() => equal(value, radioGroupContext.value), [
    value,
    radioGroupContext.value,
  ])
  const onChange = useCallback(
    (result: boolean) => {
      if (result && !equal(value, radioGroupContext.value)) {
        radioGroupContext.onChange(value)
      }
    },
    [radioGroupContext, value],
  )
  const inputRef = useRef<HTMLInputElement>(null)
  const labelRef = useRef<HTMLLabelElement>(null)
  const state = useCheck(labelRef, inputRef, checked, disabled, onChange)

  const labelClassName = clsx(styles.label, {
    [styles['label-checked']]: state.checked && sharedData.siteCode,
    [styles['label-active']]: state.active && !state.disabled,
    [styles['label-disabled']]: state.disabled,
    [styles['label-focused']]: state.focused && !state.disabled,
    [styles['label-hover']]: state.hover && !state.disabled,
  })

  const labelDarkClassName = clsx(styles.label, {
    [styles['labelDark-checked']]: state.checked,
    [styles['labelDark-active']]: state.active && !state.disabled,
    [styles['labelDark-disabled']]: state.disabled,
    [styles['labelDark-focused']]: state.focused && !state.disabled,
    [styles['labelDark-hover']]: state.hover && !state.disabled,
  })

  const labelSpanClassName = clsx(styles.radioLabel, {
    [styles['radioLabel-checked']]: state.checked && sharedData.siteCode !== TicketType.alpha_u,
    [styles['radioLabel-checked-au']]: state.checked && sharedData.siteCode === TicketType.alpha_u,
  })

  const labelDarkSpanClassName = clsx(styles.radioLabel, {
    [styles['radioLabelDark-checked']]: state.checked,
  })

  const Render = render
  return (
    <CheckContext.Provider value={state}>
      <div className={dark ? styles.RadioWrapDark : styles.RadioWrap}>
        <label
          data-testid="Radio"
          className={dark ? labelDarkClassName : labelClassName}
          ref={labelRef}
        >
          <input
            className={dark ? styles.inputDark : styles.input}
            ref={inputRef}
            type="radio"
            name={radioGroupContext.id}
            disabled={disabled}
            defaultChecked={defaultChecked}
          />
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Render {...state} />
          <span className={dark ? labelDarkSpanClassName : labelSpanClassName}>
            {label}
          </span>
          {children}
        </label>
      </div>
    </CheckContext.Provider>
  )
}
