/* eslint-disable import/prefer-default-export */
/**
 * 画面やコンポーネントを超えて汎用的に使用するcustom hooksを定義している。
 */

import { RefObject, createContext, useEffect, useState } from 'react'
import { noOperation, generateRandomNumber } from '@/helpers/utils'

/**
 * ラジオやチェックボックスのような、チェックUIの状態を表す。
 */
export type CheckState = {
  /**
   * フォーカス状態かどうか
   */
  focused: boolean
  /**
   * 押下状態かどうか
   */
  active: boolean
  /**
   * チェック状態かどうか
   */
  checked: boolean
  /**
   * 操作不能状態かどうか
   */
  disabled: boolean
  /**
   * マウスホバー状態かどうか。
   */
  hover: boolean
}

/**
 * 下位のコンポーネントとチェック状態を共有するコンテキスト。
 */
export const CheckContext = createContext<CheckState>({} as CheckState)

/**
 * カスタムチェックボックス、カスタムラジオで使用する、マウスイベントをハンドルするhook
 * @param labelRef labelのref
 * @param inputRef inputのref
 * @param checked 現実のチェック状態をセットする。
 * @param onChange 状態の変化を補足するためのイベントハンドラ。このイベントハンドラでstateを更新すること。
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCheck = (
  labelRef: RefObject<HTMLLabelElement>,
  inputRef: RefObject<HTMLInputElement>,
  checked: boolean,
  disabled: boolean,
  onChange: (value: boolean) => void,
) => {
  const id = generateRandomNumber(5)
  // 単一のラジオ、チェックに関する状態。disabledとcheckedは外部から与えられるため、状態管理外。
  const [state, setState] = useState(() => ({
    focused: false,
    active: false,
    hover: false,
  }))

  // active状態の管理。
  const [activeCount, setActiveCount] = useState(0)
  useEffect(() => {
    if (activeCount === 0 && state.active) {
      setState({ ...state, active: false })
    } else if (activeCount > 0 && !state.active) {
      setState({ ...state, active: true })
    }
  }, [activeCount, setState, state])

  // 入力要素に関するデータ同期及び、イベントハンドリング。
  useEffect(() => {
    const { current } = inputRef
    if (current) {
      current.id = id
      current.checked = checked
      const clickHandler = (event: MouseEvent) => {
        // 入力要素の変位はクリック以外でも発生する。しかし、その時ケースでもclickイベントが発生する。clickしてないのにもかかわらず。
        // この時、target.checkedに入ってるのは、preventDefaultでイベントをキャンセルしないことを前提とした次の値。
        // HTML上での状態変更は許さず、純粋にデータによってチェック状態を管理する。
        // SSOT的にもプログラム的にも、可能な限りdata => viewとすべきなのでこのような作りになっている。
        // これら全てを満たす方法として、jsxに直接イベントハンドラを仕掛けないのは、jsxが汚くなるから。あとは、jsxにたくさんハンドラを仕掛けるコードを
        // 書かせるのは、再利用の観点から見るとミスにつながりやすいから。
        // Hooksとして切り出した時の使いやすさを考慮し、refを設定するのみで導入可能にしてある。
        if (event.target instanceof HTMLInputElement) {
          event.preventDefault()
          const result = event.target.checked
          // preventと同時に設定したvalue値はhtmlに反映されないため、非同期でイベントを伝播させる。
          setImmediate(() => {
            onChange(result)
          })
        }
      }
      const focusHandler = () =>
        setState(currentState => ({ ...currentState, focused: true }))
      const blurHandler = () =>
        setState(currentState => ({ ...currentState, focused: false }))
      current.addEventListener('change', noOperation)
      current.addEventListener('click', clickHandler)
      current.addEventListener('focus', focusHandler)
      current.addEventListener('blur', blurHandler)
      return () => {
        current.removeEventListener('change', noOperation)
        current.removeEventListener('click', clickHandler)
        current.removeEventListener('focus', focusHandler)
        current.removeEventListener('blur', blurHandler)
      }
    }
    return noOperation
  }, [inputRef, setState, onChange, checked, id])

  // マウス操作に関するイベントハンドラ設定。
  useEffect(() => {
    const label = labelRef.current
    if (label) {
      label.htmlFor = id
      const mouseEnterHandler = () =>
        setState(currentState => ({ ...currentState, hover: true }))
      const mouseLeaveHandler = () =>
        setState(currentState => ({ ...currentState, hover: false }))
      const mouseDownHandler = () => {
        setActiveCount(current => current + 1)
        function mouseUpLeaveHandler() {
          // マウスアップからディレイを挟んでactive状態を戻す。クリック時間が短くても、最低200msは押下されているかのような効果を出す。
          // これをしないと押下感がいまいち出ないため。
          // カウントアップダウンにしてるのは、200ms未満の感覚でクリックを連打されてもちゃんと動作させるため。
          window.setTimeout(() => setActiveCount(current => current - 1), 200)
          if (label) {
            // mouseUpだけでなく、leaveもactive状態を終了するためのイベントになる。
            label.removeEventListener('mouseup', mouseUpLeaveHandler)
            label.removeEventListener('mouseleave', mouseUpLeaveHandler)
          }
        }
        label.addEventListener('mouseup', mouseUpLeaveHandler)
        label.addEventListener('mouseleave', mouseUpLeaveHandler)
      }
      label.addEventListener('mouseenter', mouseEnterHandler)
      label.addEventListener('mouseleave', mouseLeaveHandler)
      label.addEventListener('mousedown', mouseDownHandler)
      return () => {
        label.removeEventListener('mouseenter', mouseEnterHandler)
        label.removeEventListener('mouseLeave', mouseLeaveHandler)
        label.removeEventListener('mousedown', mouseDownHandler)
      }
    }
    return noOperation
  }, [labelRef, id])

  return { ...state, checked, disabled } as const
}
