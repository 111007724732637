import React, { ReactNode } from 'react'
import { useModal } from '@/common-hooks/useModal'

export type ModalWrapperProps = {
  render: ReactNode
  className?: string
  disabled?: boolean
}

export const ModalWrapper: React.FC<ModalWrapperProps> = ({
  render,
  className,
  disabled = false,
}) => {
  const { openModal } = useModal()
  const content = render
  return (
    <button
      disabled={disabled}
      className={className}
      onClick={() => openModal(content)}
    />
  )
}
