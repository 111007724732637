import React from 'react'
import logo from '@/assets/images/logo-alpha-u.svg'
import styles from './styles.module.scss'

export function AuHeader(): JSX.Element {
  return (
    <header className={styles.auHeader}>
      <a href="https://alpha-u.io/live/">
        <img src={logo} />
      </a>

      <ul>
        <li>
          <a href={(process.env.REACT_APP_BASE_URL ?? '') + '/alpha-u/mypage/'}>
            購入履歴
          </a>
        </li>
      </ul>
    </header>
  )
}
