import React, { useState, useEffect, useContext, Dispatch, SetStateAction } from 'react'
import { noOperation } from '@/helpers/utils'
import { Regex } from '@/helpers/regex'
import clsx from 'clsx'
import styles from './styles.module.scss'
import { SharedContext } from '@/components/parts/SharedContext'
import { TicketType } from '@/enums'

export type MaestroModalProps = {
  setiIsShown: Dispatch<SetStateAction<boolean>>
  login: (name: string) => void
}

export const MaestroModal: React.FC<MaestroModalProps> = ({
  setiIsShown = noOperation,
  login = noOperation,
}) => {
  const [name, setName] = useState('')
  const [isLoginButtonDisabled, setIsLoginButtonDisabled] = useState(true)
  const [isInvalidName, setIsInvalidName] = useState(false)

  const sharedData = useContext(SharedContext)

  useEffect(() => {
    setIsLoginButtonDisabled(!name)
  }, [name])

  function closeModal() {
    setiIsShown(false)
  }

  function loginButtonDidTap(name: string) {
    if (Regex.isPhoneNumber(name) || Regex.isEmail(name)) {
      setIsInvalidName(true)
      return
    }

    setIsInvalidName(false)
    login(name)
  }

  return (
    <div className={clsx(styles.overlay)}>
      <div className={clsx(styles.modarlWrapper)}>
        <div className={clsx(styles.contents)}>
          <button
            className={clsx(styles.closeButton)}
            onClick={() => closeModal()}
          >
            ×
          </button>
          <p className={clsx(styles.headline)}>チャット機能の利用について</p>
          <p className={clsx(styles.text)}>
            本機能は
            <a
              href="https://info.maestro.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Maestro社
            </a>
            のサービスを通じて提供する機能です。
            <br />
            「チャットを始める」ボタンを押下した時点で、
            <a
              href="https://up.auone.jp/chatguide"
              target="_blank"
              rel="noopener noreferrer"
            >
              「チャット機能」のご利用に関するお知らせ
            </a>
            を承諾したものとみなします。
            <br />
            「チャット機能」のご利用に関するお知らせは
            <a
              href="https://up.auone.jp/chatguide"
              target="_blank"
              rel="noopener noreferrer"
            >
              こちら
            </a>
            。<br />
            Maestro社のプライバシーポリシーについては
            <a
              href="https://info.maestro.io/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              こちら
            </a>
            。
          </p>

          <p className={clsx(styles.headline)}>名前を入力</p>
          <p className={clsx(styles.notice)}>
            <small>※一度決定した名前は変更することができません。</small>
          </p>
          <p className={clsx(styles.notice)}>
            <small>
              ※名前には、個人情報（電話番号、Eメールアドレスなど）を登録しないでください。
            </small>
          </p>
          <p className={clsx(styles.notice)}>
            <small>
              （個人情報を含む内容を確認した場合、チャット機能の利用を停止することがあります）
            </small>
          </p>

          <input
            className={clsx(styles.nameInput)}
            value={name}
            onChange={e => setName(e.target.value)}
            type="text"
            placeholder="名前を入力"
          />
          {isInvalidName && (
            <p className={clsx(styles.alertText)}>
              入力されたお名前はご利用になれません
            </p>
          )}

          <button
            className={clsx(styles.loginButton, {
              [styles.alphaUcolor]: sharedData.siteCode === TicketType.alpha_u,
            })}
            disabled={isLoginButtonDisabled}
            onClick={() => loginButtonDidTap(name)}
          >
            チャットを始める
          </button>
        </div>
      </div>
    </div>
  )
}
