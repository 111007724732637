import React, { useEffect, useState, useCallback } from 'react'
import { Header } from '@/components/parts/Header'
import { Footer } from '@/components/parts/Footer'
import { TelasaHeader } from '@/components/parts/TELASA/Header'
import { TelasaFooter } from '@/components/parts/TELASA/Footer'
import { AuHeader } from '@/components/parts/alpha-u/Header'
import { AuFooter } from '@/components/parts/alpha-u/Footer'
import { Loader } from '@/components/parts/Loader'
import { TransitionLink } from '@/components/parts/TransitionLink'
import IconSuccess from '@/assets/icon/icn-success.svg'
import IconInfo from '@/assets/icon/icn-info.svg'
import styles from './styles.module.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import { API } from '@/network/api-client'
import { TicketType, SitePath } from '@/enums'

// propsとして受け取る型を定義
type Props = {
  site?: string
}

export const PurchaseCompleted = (props: Props): JSX.Element => {
  // siteCode 0:uP!!! 1:TELASA 2:alpha-u
  const siteCode: number =
    props.site === 'alpha-u'
      ? TicketType.alpha_u
      : props.site === 'telasa'
      ? TicketType.telasa
      : TicketType.up

  const location = useLocation()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const transitionUrl = SitePath(siteCode) + '/mypage'

  const errHandler = useCallback(() => {
    const query = new URLSearchParams(location.search)
    const articleId = query.get('articleId') ?? ''
    const navigateUrl =
      (process.env.REACT_APP_BASE_URL ?? '') +
      SitePath(siteCode) +
      '/purchase/error/' +
      articleId
    navigate(navigateUrl)
  }, [location, navigate, siteCode])

  useEffect(() => {
    const query = new URLSearchParams(location.search)

    // type=free の場合は0円チケットの決済
    // flavor を介していないので売上確定処理を行わない
    const isFree = query.get('type')
    if (isFree) {
      setIsLoading(false)
      return
    }

    // 決済認可に必要なパラメータ paymtd はない場合がある
    const transactionId = query.get('transactionId')
    const paymtd = query.get('paymtd') ?? ''

    if (transactionId === null) {
      errHandler()
      return
    }

    const ticketSold = async () => {
      const res = await API.purchaseTicketSold(transactionId, paymtd)

      if (res.success) {
        setIsLoading(false)
      } else {
        errHandler()
      }
    }

    ticketSold().catch(() => {
      errHandler()
    })
  }, [location, errHandler])

  return (
    <div
      className={`${
        siteCode === TicketType.alpha_u
          ? styles.pageWrapperAu
          : siteCode === TicketType.telasa
          ? styles.pageWrapperTelasa
          : styles.pageWrapperUp
      }`}
    >
      {siteCode === TicketType.up && <Header />}
      {siteCode === TicketType.telasa && <TelasaHeader />}
      {siteCode === TicketType.alpha_u && <AuHeader />}
      <Loader isShown={isLoading} />
      {!isLoading && (
        <div
          className={`${
            siteCode === TicketType.alpha_u
              ? styles.contentsWrapAu
              : siteCode === TicketType.telasa
              ? styles.contentsWrapTelasa
              : styles.contentsWrapUp
          }`}
        >
          <div>
            <div className={styles.iconWrap}>
              <img className={styles.icn} src={IconSuccess} />
            </div>
            <p className={styles.message1}>購入が完了しました</p>
            {siteCode === TicketType.up && (
              <small className={styles.message2}>
                {
                  'ご購入ありがとうございました。\nご購入内容は「マイページ」からご確認いただけます。'
                }
              </small>
            )}
            {(siteCode === TicketType.telasa || siteCode === TicketType.alpha_u) && (
              /* 注意文言 */
              <div className={styles.purchaseCompletedAttention}>
                <p>
                  <img className={styles.icn} src={IconInfo} />
                  事前にご確認ください
                </p>
                <div>
                  以下のボタンから購入履歴画面をブックマークして頂くと、当日のご視聴がスムーズに行えます。
                </div>
                <div>
                  ご購入されたコンテンツページから
                  <br />
                  「購入・視聴する」ボタンを押下することでも再生することが可能です。
                </div>
              </div>
            )}
            <div className={styles.linkWrap}>
              <TransitionLink
                text={
                  siteCode === TicketType.up
                    ? 'マイページへ'
                    : '購入履歴はこちら'
                }
                type="noIcon"
                priority="primary"
                transitionUrl={transitionUrl}
              />
            </div>
          </div>
        </div>
      )}
      {siteCode === TicketType.up && <Footer />}
      {siteCode === TicketType.telasa && <TelasaFooter />}
      {siteCode === TicketType.alpha_u && <AuFooter />}
    </div>
  )
}
