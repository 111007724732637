import React from 'react'
import styles from './styles.module.scss'
import Cookies from 'js-cookie'
import { VideoServiceType } from '@/enums'
import { v4 as uuidv4 } from 'uuid'

export type IframePlayerProps = {
  iframe: string
  isPreview?: boolean
  service?: string
}

const isUrl = (content: string) => {
  let url
  try {
    url = new URL(content)
  } catch (_) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const IframePlayer: React.FC<IframePlayerProps> = ({
  iframe = '',
  isPreview = false,
  service = '',
}) => {
  if (isUrl(iframe)) {
    if (service === VideoServiceType.immersive) {
      if (isPreview === false) {
        iframe += '&appUserId='
        iframe += Cookies.get('UserID') ?? ''
      } else {
        iframe += '&appUserId='
        iframe += uuidv4()
      }
    }
    return (
      <div className={styles.playerWrapper}>
        <iframe
          src={iframe}
          allow="autoplay; encrypted-media"
          frameBorder="0"
          allowFullScreen
        />
      </div>
    )
  } else {
    return <></>
  }
}
