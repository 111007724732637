import React, { useState, useEffect, useCallback } from 'react'
import { KKSModal } from '@/components/kks/KKSModal'
import clsx from 'clsx'
import styles from './styles.module.scss'
import { API } from '@/network/api-client'
import { generateRandomNumber } from '@/helpers/utils'

export type KKSPlayerProps = {
  eventId: string
  errHandler: () => void
  isPreview?: boolean
}

type KKSIframeProps = {
  token: string
}

const KKSIframe: React.FC<KKSIframeProps> = ({ token }) => {
  return (
    <iframe
      className={clsx(styles.upKKSPlayer)}
      src={`${process.env.REACT_APP_KKS_SRC ?? ''}?t=${token}&hl=ja`}
      allow="autoplay; encrypted-media"
      frameBorder="0"
      allowFullScreen
    />
  )
}

export const KKSPlayer: React.FC<KKSPlayerProps> = ({
  eventId,
  errHandler,
  isPreview = false,
}) => {
  const [isModalShown, setIsModalShown] = useState(false)
  const [appToken, setAppToken] = useState('')
  const [userToken, setUserToken] = useState('')

  const login = useCallback(
    (name: string) => {
      const request = async () => {
        const req = isPreview ? API.requestPreviewKKSToken : API.requestKKSToken
        const res = await req(appToken, eventId, name)

        setIsModalShown(false)
        setUserToken(res.token)
      }

      request().catch(() => {
        errHandler()
      })
    },
    [isPreview, appToken, eventId, errHandler],
  )

  useEffect(() => {
    const init = async () => {
      const authRes = await API.authenticateKKS()
      setAppToken(authRes.token)

      // チャット利用可能かどうかを判断する
      const eventRes = await API.fetchKKSEvent(eventId)

      if (eventRes.isEnableChatroom) {
        setIsModalShown(true)
      } else {
        const req = isPreview ? API.requestPreviewKKSToken : API.requestKKSToken
        const tokenRes = await req(
          authRes.token,
          eventId,
          'Guest' + generateRandomNumber(5),
        )
        setUserToken(tokenRes.token)
      }
    }

    init().catch(() => {
      errHandler()
    })
  }, [isPreview, eventId, errHandler])

  return (
    <div className={clsx(styles.upKKSWrapper)}>
      {userToken !== '' && <KKSIframe token={userToken} />}
      {isModalShown && <KKSModal login={name => login(name)} />}
    </div>
  )
}
