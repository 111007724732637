import React, { useRef, useContext } from 'react'
import clsx from 'clsx'
import { noOperation, keyObject } from '@/helpers/utils'
import styles from './styles.module.scss'
import { SharedContext } from '@/components/parts/SharedContext'
import { TicketType } from '@/enums'

/**
 * 選択した優先度とclassNameのマップ。
 */
const BUTTON_PRIORITY_MAP = {
  primary: styles.primary,
  premium: styles.premium,
} as const

/**
 * リンクのタイプ
 */
const LinkTypeMap = {
  normal: 'normal',
  toNext: styles.toNext,
} as const

/**
 * ボタン種類。
 */
export const BUTTON_PRIORITY = keyObject(BUTTON_PRIORITY_MAP)

export type ButtonProps<T> = {
  /**
   * ボタン主要度
   */
  priority?: keyof typeof BUTTON_PRIORITY_MAP
  /**
   * ボタンテキスト
   */
  text?: string
  /**
   * 非活性
   */
  disabled?: boolean
  /**
   * 選択時に実行する処理。
   */
  onClick?: (value?: T) => void
  /**
   * ボタンのvalue値。
   */
  value?: T
  type?: keyof typeof LinkTypeMap
  /**
   * サイト種別
   */
  siteCode?: number
}

/**
 * アクションボタン
 */
export function Button<T>({
  priority = 'primary',
  text = '',
  disabled = false,
  onClick = noOperation,
  value = undefined,
  type = 'normal',
  siteCode = undefined,
}: ButtonProps<T>): JSX.Element {
  const sharedData = useContext(SharedContext)
  const ref = useRef<HTMLButtonElement>(null)
  if (siteCode === undefined) {
    siteCode = sharedData.siteCode
  }
  const handleClick = () => {
    onClick(value as T)
  }
  // toNextのアイコンをずらすかどうか
  const isToNextIconMove = () => {
    return text.length > 10 ? styles.longToNext : ''
  }

  return (
    <button
      {...{ disabled }}
      ref={ref}
      className={clsx(
        styles.button,
        BUTTON_PRIORITY_MAP[priority],
        LinkTypeMap[type],
        {
          [styles.disabled]: disabled,
        },
        isToNextIconMove(),
        {
          [styles.alphaUcolor]: siteCode === TicketType.alpha_u && !disabled,
        },
      )}
      type="button"
      onClick={handleClick}
      tabIndex={0}
    >
      {/* ボタンに表示する文字列が10文字を超える場合、スタイルを適用させる */}
      <div
        className={
          text.length > 10 ? `${styles.longText} buttonText` : `buttonText`
        }
      >
        {text}
      </div>
    </button>
  )
}
