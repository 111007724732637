import axios from 'axios'
import { MaestroTokenResponse } from '@/models/maestro'
import { TicketGroupResponse } from '@/models/ticket'
import { KKSEvent, KKSTokenResponse } from '@/models/kks'
import { VideoListResponse, VideoResponse } from '@/models/video'
import { ViewingAvailabilityResponse } from '@/models/live'
import {
  PersonalConfirmResponse,
  PurchasedHistoryResponse,
} from '@/models/purchasedHistory'
import { PurchasedTicketsResponse } from '@/models/purchasedTicket'
import { MemberTypeResponse } from '@/models/memberType'
import { PersonalInfo, TicketAuthoryResponse } from '@/models/ticketAuthory'
import { APIBaseResponse } from '@/models/api-base-response'
import { AuidResponse } from '@/models/auid'
import { VerifySerialCodeResponse } from '@/models/verifySerial'
import { StripeAuthoryResponse } from '@/models/stripeAuthory'
import { UnpaidTicketsResponse } from '@/models/unpaid'
import { UserSettingResponse } from '@/models/userSetting'

const baseURL = process.env.REACT_APP_BASE_API_URL
const axiosInstance = axios.create({ baseURL })

export interface APIError {
  code?: number
  message: string
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
function errorHandling(e: any): APIError {
  if (axios.isAxiosError(e)) {
    return {
      code: e.response?.status,
      message: e.message,
    }
  } else {
    return {
      code: -1,
      message: 'Unknown Error.',
    }
  }
}

const client = {
  async get<T>(path: string, params = {}) {
    const res = await axiosInstance
      .get<T>(path, { params, withCredentials: true })
      .catch(e => {
        return Promise.reject(errorHandling(e))
      })

    return Promise.resolve(res.data)
  },

  async post<T>(path: string, params = {}) {
    const res = await axiosInstance
      .post<T>(path, params, { withCredentials: true })
      .catch(e => {
        return Promise.reject(errorHandling(e))
      })

    return Promise.resolve(res.data)
  },

  async put<T>(path: string, params = {}) {
    const res = await axiosInstance
      .put<T>(path, params, { withCredentials: true })
      .catch(e => {
        return Promise.reject(errorHandling(e))
      })

    return Promise.resolve(res.data)
  },
  async delete<T>(path: string) {
    const res = await axiosInstance
      .delete<T>(path, { withCredentials: true })
      .catch(e => {
        return Promise.reject(errorHandling(e))
      })

    return Promise.resolve(res.data)
  },
}

export const API = {
  fetchMemberType: (siteCode: number): Promise<MemberTypeResponse> => {
    return client.get<MemberTypeResponse>(
      `/member/membertype?siteCode=${String(siteCode)}`,
    )
  },
  authenticateKKS: (): Promise<KKSTokenResponse> => {
    return client.post<KKSTokenResponse>('/kks/authenticate')
  },
  requestKKSToken: (
    appToken: string,
    eventId: string,
    name: string,
  ): Promise<KKSTokenResponse> => {
    return client.post<KKSTokenResponse>('kks/requestToken', {
      appToken,
      eventId,
      name,
    })
  },
  requestPreviewKKSToken: (
    appToken: string,
    eventId: string,
    name: string,
  ): Promise<KKSTokenResponse> => {
    return client.post<KKSTokenResponse>('kks/requestPreveiewToken', {
      appToken,
      eventId,
      name,
    })
  },
  fetchKKSEvent: (id: string): Promise<KKSEvent> => {
    return client.get<KKSEvent>(`kks/event/${id}`)
  },
  requestMaestroToken: (): Promise<MaestroTokenResponse> => {
    return client.post<MaestroTokenResponse>('/maestro/requestToken')
  },
  requestPreviewMaestroToken: (): Promise<MaestroTokenResponse> => {
    return client.post<MaestroTokenResponse>('/maestro/requestPreviewToken')
  },
  fetchVideo: (videoId: number): Promise<VideoResponse> => {
    return client.get<VideoResponse>('/findVideoById', { id: videoId })
  },
  fetchPreviewVideo: (id: string): Promise<VideoResponse> => {
    return client.get<VideoResponse>('/findVideoByUid', { uid: id })
  },
  fetchVideoList: (ticketId: number): Promise<VideoListResponse> => {
    return client.get<VideoListResponse>('/videos', { ticketId })
  },
  fetchTicketList: (ticketGroupId: number): Promise<TicketGroupResponse> => {
    return client.get<TicketGroupResponse>('/ticketGroup', {
      ticketGroupId,
    })
  },
  fetchPurchasedTickets: (
    ticketGroupId: number,
  ): Promise<PurchasedTicketsResponse> => {
    return client.post<PurchasedTicketsResponse>('/purchasedTickets', {
      ticketGroupId,
    })
  },
  fetchPurchasedHistory: (
    siteCode: number,
    offset: number,
  ): Promise<PurchasedHistoryResponse> => {
    return client.post<PurchasedHistoryResponse>('/purchasedHistory', {
      siteCode: siteCode,
      limit: 10,
      offset,
    })
  },
  checkViewingAvailability: (
    videoId: number,
  ): Promise<ViewingAvailabilityResponse> => {
    return client.post<ViewingAvailabilityResponse>('/isAvailableForViewing', {
      videoId,
    })
  },
  purhcaseTicketAuthory: (
    ticketId: number,
    priceId: number,
    commodity: string,
    authOKURL: string,
    authNGURL: string,
    articleId: number,
    // シリアルコード
    serialCode: string | null,
    // 名前（コンビニ決済用）
    name: string | null,
    // メールアドレス（コンビニ決済用）
    mail: string | null,
    // 個人情報
    personalInfo: PersonalInfo | null,
    // メールマガジン
    promotionMail?: boolean | null,
  ): Promise<TicketAuthoryResponse> => {
    return client.post<TicketAuthoryResponse>('/ticketAuthory', {
      ticketId,
      pid: priceId,
      commodity,
      authOKURL,
      authNGURL,
      articleId,
      serialCode,
      name,
      mail,
      personalInfo,
      promotionMail,
    })
  },
  purhcaseTicketCoupon: (
    ticketId: number,
    priceId: number,
    commodity: string,
    authOKURL: string,
    authNGURL: string,
    articleId: number,
    // シリアルコード
    serialCode: string | null,
    // 個人情報
    personalInfo: PersonalInfo | null,
    // メールマガジン
    promotionMail?: boolean | null,
  ): Promise<TicketAuthoryResponse> => {
    return client.post<TicketAuthoryResponse>('/ticketCoupon', {
      ticketId,
      pid: priceId,
      commodity,
      authOKURL,
      authNGURL,
      articleId,
      serialCode,
      personalInfo,
      promotionMail,
    })
  },
  purchaseTicketSold: (
    transactionId: string,
    paymtd: string,
  ): Promise<APIBaseResponse> => {
    return client.post<APIBaseResponse>('/ticketSold', {
      transactionId,
      paymtd,
    })
  },
  getAuid: (): Promise<AuidResponse> => {
    return client.get<AuidResponse>('/auid')
  },
  authenticate: (): Promise<APIBaseResponse> => {
    return client.get<APIBaseResponse>('/login')
  },
  updateUniqueUser: (
    userId: string,
    videoId: number,
  ): Promise<APIBaseResponse> => {
    return client.post<APIBaseResponse>('updateUniqueUser', {
      userId,
      videoId,
    })
  },
  fetchUserSetting: (siteCode: number): Promise<UserSettingResponse> => {
    return client.get<UserSettingResponse>('/user/setting/' + String(siteCode))
  },
  updateUserSetting: (
    siteCode: number,
    promotionMail: boolean,
  ): Promise<UserSettingResponse> => {
    return client.post<UserSettingResponse>('/user/setting', {
      siteCode,
      promotionMail,
    })
  },
  // シリアルコードの検証を行う
  verifySerialCode: (
    // チケットID
    ticketId: number,
    // シリアルコード
    serialCode: string,
  ): Promise<VerifySerialCodeResponse> => {
    return client.post<VerifySerialCodeResponse>('/serial/verify', {
      ticketId,
      serialCode,
    })
  },

  // コンビニ決済処理
  purhcaseStripeAuthory: (
    ticketId: number, // チケットID
    articleId: number, // 記事ID
    pid: number, // PriceレコードのID
    serialCode: string | null, // シリアルコード
    name: string | null, // 名前(コンビニ決済用)
    email: string | null, // メールアドレス(コンビニ決済用)
    personalInfo: PersonalInfo | null, // 個人情報
    promotionMail?: boolean | null, // メールマガジン
  ): Promise<StripeAuthoryResponse> => {
    return client.post<StripeAuthoryResponse>('/stripe/authory', {
      ticketId,
      articleId,
      pid,
      serialCode,
      name,
      email,
      personalInfo,
      promotionMail,
    })
  },

  // コンビニ未払い一覧
  unpaidTickets: (siteCode: number): Promise<UnpaidTicketsResponse> => {
    return client.get<UnpaidTicketsResponse>(
      '/unpaidTickets/' + String(siteCode),
    )
  },

  // 指定されたチケットの個人情報の一覧を取得
  personalConfirm: (
    // お客様情報ID
    personalId: number,
  ): Promise<PersonalConfirmResponse> => {
    return client.get<PersonalConfirmResponse>('/personal/confirm', {
      id: personalId,
    })
  },
}
