export const VideoServiceType = {
  kks: 'BV-Live',
  maestro: 'Maestro',
  vimeo: 'Vimeo',
  immersive: 'Immersive',
  unityaws: 'Unity+AWS',
} as const

export const VideoType = {
  live: 1,
  archive: 2,
  liveAndArchive: 3,
  sample: 8,
} as const

export const TicketAllowType = {
  unnecessary: 0,
  necessary: 1,
} as const

export const DuplicatePurchase = {
  able: 0,
  disable: 1,
} as const

export const MemberType = {
  general: 0,
  premium: 1,
  telasaGeneral: 10,
  videoPass: 11,
}

export const PriceMemberType = {
  general: 0,
  premium: 1,
  telasaGeneral: 10,
  videoPass: 11,
}

export const TicketType = {
  up: 0,
  telasa: 1,
  alpha_u: 2,
}

export const SitePath = (siteCode: number) : string => {
  if (siteCode === TicketType.alpha_u) {
    return '/alpha-u'
  } else if (siteCode === TicketType.telasa) {
    return '/telasa'
  }
  return ''
}
