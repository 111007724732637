import React from 'react'
import styles from './styles.module.scss'
import { Button } from '@/components/parts/Button'
import { TicketType } from '@/enums'

export type MembershipOnlyProps = {
  siteCode: number
  joinAction: () => void
}

export function MembershipOnly({
  siteCode,
  joinAction,
}: MembershipOnlyProps): JSX.Element {
  const isPontapass =
    siteCode === TicketType.up || siteCode === TicketType.alpha_u
  return (
    <div className={styles.linkWrap}>
      <div>
        <p>
          {!isPontapass
            ? '見放題プラン加入者限定コンテンツのため購入できません'
            : 'Pontaパス限定コンテンツのため購入できません'}
        </p>
        <Button
          text={
            !isPontapass
              ? '見放題プラン新規加入はこちら'
              : 'Pontaパス\n新規入会はこちら'
          }
          type="toNext"
          priority="premium"
          onClick={() => {
            joinAction()
          }}
          siteCode={0}
        />
      </div>
    </div>
  )
}
