import React, { useState, useEffect, useRef, useCallback } from 'react'
import { MaestroModal } from '@/components/maestro/MaestroModal'
import MaestroIFrameSDK, { EventType, TheaterMode } from '@maestro_io/web-sdk'
import { API } from '@/network/api-client'
import clsx from 'clsx'
import styles from './styles.module.scss'

export type MaestroPlayerProps = {
  slug: string
  provider: string
  errHandler: () => void
  isPreview?: boolean
}

export const MaestroPlayer: React.FC<MaestroPlayerProps> = ({
  slug = '',
  provider = '',
  errHandler,
  isPreview = false,
}) => {
  const [isModalShown, setIsModalShown] = useState(false)
  const platform = useRef<HTMLDivElement>(null)
  const [maestro, setMaestro] = useState(() => {
    return new MaestroIFrameSDK({
      element: platform.current,
      slug,
    })
  })

  const login = useCallback(
    (name: string) => {
      const request = async () => {
        const req = isPreview
          ? API.requestPreviewMaestroToken
          : API.requestMaestroToken
        const res = await req()
        const isSuccess = await maestro.login({
          name,
          service: provider,
          thirdPartyAccountId: res.hash,
          jwt: res.token,
        })

        if (!isSuccess) {
          errHandler()
          return
        }
        setIsModalShown(false)
      }

      request().catch(() => {
        errHandler()
      })
    },
    [isPreview, maestro, provider, errHandler],
  )

  useEffect(() => {
    setMaestro(
      new MaestroIFrameSDK({
        element: platform.current,
        slug,
      }),
    )
  }, [slug, platform])

  useEffect(() => {
    try {
      maestro.render({ theater: TheaterMode.DISABLED })

      maestro.on(EventType.REQUEST_LOGIN || 'request:login', () => {
        setIsModalShown(true)
      })

      maestro.on(EventType.REQUEST_LOGOUT, () => {
        maestro
          .logout()
          .then(() => {
            // do nothing
          })
          .catch(() => {
            // do nothing
          })
      })
    } catch {
      // do nothing
    }
  }, [maestro])

  return (
    <div
      className={clsx(styles.upMaestroWrapper)}
      ref={platform}
      id="my-embedded-maestro-platform"
    >
      {isModalShown && (
        <MaestroModal
          setiIsShown={setIsModalShown}
          login={name => login(name)}
        />
      )}
    </div>
  )
}
