import React, { useCallback } from 'react'
import { Button } from '@/components/parts/Button'
import back from '@/assets/icon/icn-back.svg'
import styles from './styles.module.scss'
import { useNavigate, useLocation } from 'react-router-dom'
import { SitePath, TicketType } from '@/enums'

// propsとして受け取る型を定義
type Props = {
  site?: string
}

export const PageOffHours = (props: Props): JSX.Element => {
  // siteCode 0:uP!!! 1:TELASA 2:alpha-u
  const siteCode: number =
    props.site === 'alpha-u'
      ? TicketType.alpha_u
      : props.site === 'telasa'
      ? TicketType.telasa
      : TicketType.up

  const ticketData = useLocation().state as {
    ticketGroupId: number
    articleId: number
  }
  const navigate = useNavigate()
  const openArticlePage = useCallback(() => {
    if (process.env.REACT_APP_TOP_PAGE_URL && ticketData.articleId) {
      window.open(
        process.env.REACT_APP_TOP_PAGE_URL +
          SitePath(siteCode) +
          `/live/${ticketData.articleId}`,
      )
    }
  }, [ticketData])

  return (
    <div className={styles.pageWrap}>
      <header className={styles.header}>
        <button
          className={styles.toBack}
          onClick={() =>
            navigate(
              `/purchase/select/${ticketData.ticketGroupId}?articleId=${ticketData.articleId}`,
              { replace: true },
            )
          }
        >
          <img src={back} />
        </button>
        <h1 className={styles.title}>ライブの視聴</h1>
      </header>
      <div className={styles.linkWrap}>
        <p>ただいまの時間は配信時間外です</p>
        <Button
          text="記事に戻る"
          priority="primary"
          type="normal"
          onClick={openArticlePage}
        />
      </div>
    </div>
  )
}
