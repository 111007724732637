import React, { useCallback, useState } from 'react'
import styles from './styles.module.scss'
import { OptionPrice, Ticket } from '@/models/ticket'
import { useLocation, useNavigate } from 'react-router-dom'
import back from '@/assets/icon/icn-back.svg'
import { Button } from '@/components/parts/Button'
import { Dayjs } from 'dayjs'
import { API } from '@/network/api-client'
import { TicketType, SitePath } from '@/enums'

/** 個人情報入力フォームの型定義 */
interface formValidation {
  name: string | number | readonly string[] | undefined /** 名前 */
  tel: string | number | readonly string[] | undefined /** 電話番号 */
  mail: string | number | readonly string[] | undefined /** メールアドレス */
  confirmationMail:
    | string
    | number
    | readonly string[]
    | undefined /** メールアドレス(確認) */
  postalCode: string | number | readonly string[] | undefined /** 郵便番号 */
  address: string | number | readonly string[] | undefined /** 住所 */
}

/** バリデーションの型定義 */
interface validationInterface {
  nameError: string | null /** 名前 */
  telError: string | null /** 電話番号 */
  mailError: string | null /** メールアドレス */
  confirmationMailError: string | null /** メールアドレス(確認) */
  postalCodeError: string | null /** 郵便番号 */
  addressError: string | null /** 住所 */
}

/** 個人情報の型定義 */
interface personalInfoInterface {
  name: string /** 名前 */
  tel: string /** 電話番号 */
  mail: string /** メールアドレス */
  postalCode: string /** 郵便番号 */
  address: string /** 住所 */
}

export function PersonalData(): JSX.Element {
  const navigate = useNavigate()
  const ticketData = useLocation().state as {
    ticketGroupId: number
    ticket: Ticket
    options: OptionPrice[]
    articleId: string
    isDirect: boolean
    siteCode: number
    serialCode: string | null
    serialLockLiftTime: Dayjs | null
    beforeSerialCode: string | null
    serialInvalidCount: number
    pid: number /** PriceレコードのID */
    price: number /** チケット金額 */
    isMembership: boolean /** 会員コンテンツか */
    generalPrice: number /** 一般価格 */
    membershipPrice: number /** 会員価格 */
    // --------------------------------------------
    optionTotal: string /** システム手数料計 */
    discountTitle: string /** 割引名 */
    discountPrice: number /** 割引金額 */
    formName: string /** 入力フォームのお名前 */
    formTel: string /** 入力フォームの電話番号 */
    formMail: string /** 入力フォームのメールアドレス */
    formConfirmationMail: string /** 入力フォームのメールアドレス(確認) */
    formPostalCode: string /** 入力フォームの郵便番号 */
    formAddress: string /** 入力フォームの住所 */
    promotionMail?: boolean | null // メールマガジン
    freeCoupon: number /** 無料視聴クーポン */
  }

  // 無料視聴クーポン判定フラグ
  const isFreeCoupon = Boolean(ticketData.freeCoupon)

  // 前の画面に戻る
  const backPage = () => {
    const url = '/purchase/confirm'
    navigate(url, {
      state: {
        ticketGroupId: ticketData.ticketGroupId,
        ticket: ticketData.ticket,
        options: ticketData.options,
        articleId: ticketData.articleId,
        isDirect: ticketData.isDirect,
        siteCode: ticketData.siteCode,
        serialLockLiftTime: ticketData.serialLockLiftTime,
        beforeSerialCode: ticketData.beforeSerialCode,
        serialInvalidCount: 1,
        promotionMail: ticketData.promotionMail,
        freeCoupon: ticketData.freeCoupon,
      },
      replace: true,
    })
  }

  // 必須入力チェック
  const mandatoryValidation = (val: string | null) => !val
  // 入力内容チェック
  const checkValidation = (val: string | null, regex: RegExp) =>
    !val?.match(regex)

  const errPageUrl =
    (process.env.REACT_APP_BASE_URL ?? '') +
    SitePath(ticketData.siteCode) +
    `/purchase/error/${ticketData.articleId}`

  const errHandler = useCallback(() => {
    window.open(errPageUrl, '_parent', 'noreferrer')
  }, [errPageUrl])

  // 個人情報入力フォーム
  const PersonalDataForm = () => {
    // 入力フォーム
    const [form, setForm] = useState<formValidation>({
      name: ticketData.formName, // お名前
      tel: ticketData.formTel, // 電話番号
      mail: ticketData.formMail, // メールアドレス
      confirmationMail: ticketData.formConfirmationMail, // メールアドレス(確認)
      postalCode: ticketData.formPostalCode, // 郵便番号
      address: ticketData.formAddress, // 住所
    })
    // バリデーションエラー
    const [validationError, setValidationError] = useState<validationInterface>(
      {
        nameError: null, // 名前エラー
        telError: null, // 電話番号エラー
        mailError: null, // メールアドレスエラー
        confirmationMailError: null, // メールアドレス(確認)エラー
        postalCodeError: null, // 郵便番号エラー
        addressError: null, // 住所エラー
      },
    )
    // 確認フラグ
    const [confirmFlag, setConfirmFlag] = useState(false)
    // 確認画面で次に進む場合の文言(合計金額 - 割引金額が0以下の場合は、購入する、合計金額 - 割引金額が1以上の場合は決済画面へ進む)
    const confirmText = isFreeCoupon
      ? '入力を完了する'
      : ticketData.price +
          Number(ticketData.optionTotal) -
          ticketData.discountPrice >
        0
      ? '決済画面へ進む'
      : '購入する'
    // タイトル
    const title = !confirmFlag
      ? ticketData.ticket.personalForm?.title ?? ''
      : '入力内容の確認'
    // 説明文
    const subject = !confirmFlag
      ? ticketData.ticket.personalForm?.content ?? ''
      : isFreeCoupon
      ? '<p>入力内容に相違がないことをご確認いただき、<br />完了画面へお進みください。</p>'
      : '<p>入力内容に相違がないことをご確認いただき、<br />決済画面へお進みください。</p>'

    // 名前のバリデーション
    const nameValidation = () => {
      // 名前未入力
      if (mandatoryValidation(ticketData.formName)) {
        setValidationError({
          ...validationError,
          nameError: '※ お名前の入力は必須です。',
        })
      } else {
        setValidationError({ ...validationError, nameError: null })
        return true
      }
      return false
    }

    // 電話番号のバリデーション
    const telValidation = () => {
      // eslint-disable-next-line
      const telRegex = /^0[0-9]{9,10}$/
      // 電話番号未入力
      if (mandatoryValidation(ticketData.formTel)) {
        setValidationError({
          ...validationError,
          telError: '※ 電話番号の入力は必須です。',
        })
      } else if (checkValidation(ticketData.formTel, telRegex)) {
        // 電話番号は0始まりで、数字のみで10桁もしくは11桁以外の場合はエラー
        setValidationError({
          ...validationError,
          telError: '※ 入力内容が正しくありません。',
        })
      } else {
        setValidationError({ ...validationError, telError: null })
        return true
      }
      return false
    }

    // eslint-disable-next-line
    const mailRegex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    // メールアドレスのバリデーション
    const mailValidation = () => {
      // メールアドレス未入力
      if (mandatoryValidation(ticketData.formMail)) {
        setValidationError({
          ...validationError,
          mailError: '※ メールアドレスの入力は必須です。',
        })
      } else if (
        !mandatoryValidation(ticketData.formConfirmationMail) &&
        ticketData.formMail !== ticketData.formConfirmationMail
      ) {
        // 確認用メールアドレスが入力されている & メールアドレスが一致しない
        setValidationError({
          ...validationError,
          mailError: '※ メールアドレスが一致しません。',
        })
      } else if (checkValidation(ticketData.formMail, mailRegex)) {
        // メールアドレス形式エラー
        setValidationError({
          ...validationError,
          mailError: '※ 入力内容が正しくありません。',
        })
      } else {
        setValidationError({ ...validationError, mailError: null })
        return true
      }
      return false
    }
    // メールアドレス(確認)のバリデーション
    const mailConfValidation = () => {
      // メールアドレス(確認)未入力
      if (mandatoryValidation(ticketData.formConfirmationMail)) {
        setValidationError({
          ...validationError,
          confirmationMailError: '※ メールアドレス（確認）の入力は必須です。',
        })
      } else if (
        !mandatoryValidation(ticketData.formMail) &&
        ticketData.formMail !== ticketData.formConfirmationMail
      ) {
        // メールアドレスが入力されている & メールアドレスが一致しない
        setValidationError({
          ...validationError,
          confirmationMailError: '※ メールアドレスが一致しません。',
        })
      } else if (checkValidation(ticketData.formConfirmationMail, mailRegex)) {
        // メールアドレス形式エラー
        setValidationError({
          ...validationError,
          confirmationMailError: '※ 入力内容が正しくありません。',
        })
      } else {
        setValidationError({ ...validationError, confirmationMailError: null })
        return true
      }
      return false
    }

    // 郵便番号
    const postalCodeValidation = () => {
      // eslint-disable-next-line
      const postalCodeRegex = /^[0-9]{7}$/
      // 郵便番号未入力
      if (mandatoryValidation(ticketData.formPostalCode)) {
        setValidationError({
          ...validationError,
          postalCodeError: '※ 郵便番号の入力は必須です。',
        })
      } else if (checkValidation(ticketData.formPostalCode, postalCodeRegex)) {
        // 数字7桁以外はエラー
        setValidationError({
          ...validationError,
          postalCodeError: '※ 入力内容が正しくありません。',
        })
      } else {
        setValidationError({ ...validationError, postalCodeError: null })
        return true
      }
      return false
    }

    // 住所のバリデーション
    const addressValidation = () => {
      // 住所未入力
      if (mandatoryValidation(ticketData.formAddress)) {
        setValidationError({
          ...validationError,
          addressError: '※ 住所の入力は必須です。',
        })
      } else {
        setValidationError({ ...validationError, addressError: null })
        return true
      }
      return false
    }

    // 入力内容の確認
    const confirmation = () => {
      setConfirmFlag(true)
    }
    // 入力内容修正
    const inputFix = () => {
      setConfirmFlag(false)
    }

    // お支払方法の選択へ進む
    const payment = () => {
      if (isFreeCoupon) {
        // 無料視聴クーポンの確定を行う
        // eslint-disable-next-line
        couponButtonClicked(
          ticketData.ticket.id,
          ticketData.pid,
          ticketData.ticket.title,
        )
      } else if (
        ticketData.price +
          Number(ticketData.optionTotal) -
          ticketData.discountPrice <=
        0
      ) {
        // 決済金額が0円の場合
        // eslint-disable-next-line
        purchaseButtonClicked(
          ticketData.ticket.id,
          ticketData.pid,
          ticketData.ticket.title,
        )
      } else if (ticketData.siteCode === TicketType.telasa) {
        // TELASAの場合は、コンビニ決済が選択出来るとマズイので、かんたん決済のみ行う
        // eslint-disable-next-line
        purchaseButtonClicked(
          ticketData.ticket.id,
          ticketData.pid,
          ticketData.ticket.title,
        )
      } else {
        // 遷移先URL
        const url = '/purchase/payment'
        navigate(url, {
          state: {
            ticketGroupId: ticketData.ticketGroupId,
            ticket: ticketData.ticket,
            options: ticketData.options,
            articleId: ticketData.articleId,
            isDirect: ticketData.isDirect,
            siteCode: ticketData.siteCode,
            serialCode: ticketData.serialCode, // 入力したシリアルコード
            pid: ticketData.pid, // PriceレコードのID
            isMembership: ticketData.isMembership, // 会員コンテンツか
            generalPrice: ticketData.generalPrice, // 一般価格
            membershipPrice: ticketData.membershipPrice, // 会員価格
            optionTotal: ticketData.optionTotal, // システム手数料計
            discountTitle: ticketData.discountTitle, // 割引名
            discountPrice: ticketData.discountPrice, // 割引金額
            formName: ticketData.formName, // お名前
            formTel: ticketData.formTel, // 電話番号
            formMail: ticketData.formMail, // メールアドレス
            formConfirmationMail: ticketData.formConfirmationMail, // メールアドレス(確認)
            formPostalCode: ticketData.formPostalCode, // 郵便番号
            formAddress: ticketData.formAddress, // 住所
            name: ticketData.formName, // コンビニ決済用の名前(個人情報が入力されている場合はそのまま使用)
            mail: ticketData.formMail, // コンビニ決済用のメールアドレス(個人情報が入力されている場合はそのまま使用)
            promotionMail: ticketData.promotionMail, // メールマガジン
            freeCoupon: ticketData.freeCoupon, // 無料視聴クーポン
          },
        })
      }
    }

    // かんたん決済
    const purchaseButtonClicked = useCallback(
      (ticketId: number, priceId: number, commodity: string) => {
        // 送信する個人情報
        const personalInfo: personalInfoInterface = {
          name: ticketData.formName, // 名前
          tel: ticketData.formTel, // 電話番号
          mail: ticketData.formMail, // メールアドレス
          postalCode: ticketData.formPostalCode, // 郵便番号
          address: ticketData.formAddress, // 住所
        }
        // 簡単決済のシステムでトランザクションIDを発行したのち、システム側のページに遷移させる
        const ticketAuthory = async () => {
          const baseUrl = process.env.REACT_APP_BASE_URL ?? ''
          const completePageUrl =
            baseUrl + SitePath(ticketData.siteCode) + '/purchase/complete'
          const res = await API.purhcaseTicketAuthory(
            ticketId,
            priceId,
            commodity,
            completePageUrl,
            errPageUrl,
            Number(ticketData.articleId),
            // クーポンコード
            ticketData.serialCode,
            // 名前（コンビニ決済用）
            null,
            // メールアドレス（コンビニ決済用）
            null,
            // 個人情報
            personalInfo,
            // メールマガジン
            ticketData.promotionMail,
          )

          if (!res.success) {
            errHandler()
            return
          }

          window.open(res.result.redirectUrl, '_parent', 'noreferrer')
        }

        ticketAuthory().catch(() => {
          errHandler()
        })
      },
      [],
    )

    // 無料視聴クーポンの適用処理
    const couponButtonClicked = useCallback(
      (ticketId: number, priceId: number, commodity: string) => {
        // 送信する個人情報
        const personalInfo: personalInfoInterface = {
          name: ticketData.formName, // 名前
          tel: ticketData.formTel, // 電話番号
          mail: ticketData.formMail, // メールアドレス
          postalCode: ticketData.formPostalCode, // 郵便番号
          address: ticketData.formAddress, // 住所
        }
        // 簡単決済のシステムでトランザクションIDを発行したのち、システム側のページに遷移させる
        const TicketCoupon = async () => {
          const completePageUrl =
            (process.env.REACT_APP_BASE_URL ?? '') +
            SitePath(ticketData.siteCode) +
            '/purchase/complete'

          const res = await API.purhcaseTicketCoupon(
            ticketId,
            priceId,
            commodity,
            completePageUrl,
            errPageUrl,
            Number(ticketData.articleId),
            // クーポンコード
            ticketData.serialCode,
            // 個人情報
            personalInfo,
            // メールマガジン
            ticketData.promotionMail,
          )

          if (!res.success) {
            errHandler()
            return
          }

          window.open(res.result.redirectUrl, '_parent', 'noreferrer')
        }

        TicketCoupon().catch(() => {
          errHandler()
        })
      },
      [],
    )

    return (
      <>
        {/* ヘッダー */}
        <div className={styles.personalDataBase}>
          {/* タイトル */}
          <div className={`${styles.contentsTitle} ${ticketData.siteCode === TicketType.alpha_u ? styles.alphaUcolor : ''}`}>
            <p>{title}</p>
          </div>
          {/* 説明文 */}
          <div
            className={styles.contentsSubject}
            dangerouslySetInnerHTML={{ __html: subject }}
          ></div>
          {/* 注釈 (確認画面では表示しない) */}
          {!confirmFlag && (
            <div className={styles.contentsSubject}>
              <p className={styles.contentsAttention}>
                ※ 全項目、記入が必須となります。
              </p>
            </div>
          )}
        </div>

        {/* 入力フォーム */}
        <div className={`${styles.personalDataBase}`}>
          {/* お名前 */}
          <div className={styles.personalDataSection}>
            <p className={styles.personalDataLabel}>お名前</p>
            <div>
              {/* 確認前 */}
              {!confirmFlag && (
                <>
                  <input
                    className={`${styles.personalDataInput} ${
                      validationError.nameError ? styles.error : ''
                    }`}
                    type="text"
                    name="name"
                    maxLength={50}
                    placeholder="山田 太郎"
                    value={form.name}
                    onChange={e => {
                      setForm({ ...form, name: e.target.value.trimStart() })
                      ticketData.formName = e.target.value.trimStart()
                    }}
                    onBlur={() => nameValidation()}
                    defaultValue={ticketData.formName}
                  />
                  {/* バリデーションエラー文言 */}
                  <small className={styles.validationErrorText}>
                    {validationError.nameError}
                  </small>
                </>
              )}
              {/* 入力内容の確認 */}
              {confirmFlag && (
                <p className={styles.inputConfirm}>{ticketData.formName}</p>
              )}
            </div>
          </div>
          {/* 電話番号 */}
          <div className={styles.personalDataSection}>
            <p className={styles.personalDataLabel}>電話番号（ハイフンなし）</p>
            <div>
              {/* 確認前 */}
              {!confirmFlag && (
                <>
                  <input
                    className={`${styles.personalDataInput} ${
                      validationError.telError ? styles.error : ''
                    }`}
                    type="tel"
                    name="tel"
                    maxLength={11}
                    placeholder="09000000000"
                    value={form.tel}
                    onChange={e => {
                      setForm({ ...form, tel: e.target.value.trimStart() })
                      ticketData.formTel = e.target.value.trimStart()
                    }}
                    onBlur={() => telValidation()}
                    defaultValue={ticketData.formTel}
                  />
                  {/* バリデーションエラー文言 */}
                  <small className={styles.validationErrorText}>
                    {validationError.telError}
                  </small>
                </>
              )}
              {/* 入力内容の確認 */}
              {confirmFlag && (
                <p className={styles.inputConfirm}>{ticketData.formTel}</p>
              )}
            </div>
          </div>
          {/* メールアドレス */}
          <div className={styles.personalDataSection}>
            <p className={styles.personalDataLabel}>メールアドレス</p>
            <div>
              {/* 確認前 */}
              {!confirmFlag && (
                <>
                  <input
                    className={`${styles.personalDataInput} ${
                      validationError.mailError ? styles.error : ''
                    }`}
                    name="mail"
                    maxLength={256}
                    placeholder="sample@example.com"
                    value={form.mail}
                    onChange={e => {
                      setForm({ ...form, mail: e.target.value.trimStart() })
                      ticketData.formMail = e.target.value.trimStart()
                    }}
                    onBlur={() => mailValidation()}
                    defaultValue={ticketData.formMail}
                  />
                  {/* バリデーションエラー文言 */}
                  <small className={styles.validationErrorText}>
                    {validationError.mailError}
                  </small>
                </>
              )}
              {/* 入力内容の確認 */}
              {confirmFlag && (
                <p className={styles.inputConfirm}>{ticketData.formMail}</p>
              )}
            </div>
          </div>
          {/* メールアドレス(確認) (確認画面では表示しない) */}
          {!confirmFlag && (
            <div className={styles.personalDataSection}>
              <p className={styles.personalDataLabel}>メールアドレス（確認）</p>
              <div>
                <input
                  className={`${styles.personalDataInput} ${
                    validationError.confirmationMailError ? styles.error : ''
                  }`}
                  name="mail"
                  maxLength={256}
                  placeholder="sample@example.com"
                  value={form.confirmationMail}
                  onChange={e => {
                    setForm({
                      ...form,
                      confirmationMail: e.target.value.trimStart(),
                    })
                    ticketData.formConfirmationMail = e.target.value.trimStart()
                  }}
                  onBlur={() => mailConfValidation()}
                  defaultValue={ticketData.formConfirmationMail}
                />
                {/* バリデーションエラー文言 */}
                <small className={styles.validationErrorText}>
                  {validationError.confirmationMailError}
                </small>
              </div>
            </div>
          )}
          {/* 郵便番号 */}
          <div className={styles.personalDataSection}>
            <p className={styles.personalDataLabel}>郵便番号（ハイフンなし）</p>
            <div>
              {/* 確認前 */}
              {!confirmFlag && (
                <>
                  <input
                    className={`${styles.personalDataInput} ${
                      validationError.postalCodeError ? styles.error : ''
                    }`}
                    type="tel"
                    name="postalCode"
                    maxLength={7}
                    placeholder="1638001"
                    value={form.postalCode}
                    onChange={e => {
                      setForm({
                        ...form,
                        postalCode: e.target.value.trimStart(),
                      })
                      ticketData.formPostalCode = e.target.value.trimStart()
                    }}
                    onBlur={() => postalCodeValidation()}
                    defaultValue={ticketData.formPostalCode}
                  />
                  {/* バリデーションエラー文言 */}
                  <small className={styles.validationErrorText}>
                    {validationError.postalCodeError}
                  </small>
                </>
              )}
              {/* 入力内容の確認 */}
              {confirmFlag && (
                <p className={styles.inputConfirm}>
                  {ticketData.formPostalCode}
                </p>
              )}
            </div>
          </div>
          {/* 住所 */}
          <div className={styles.personalDataSection}>
            <p className={styles.personalDataLabel}>住所</p>
            <div>
              {/* 確認前 */}
              {!confirmFlag && (
                <>
                  <input
                    className={`${styles.personalDataInput} ${
                      validationError.addressError ? styles.error : ''
                    }`}
                    type="text"
                    name="address"
                    maxLength={256}
                    placeholder="東京都新宿区西新宿2-8-1　○○ビル"
                    value={form.address}
                    onChange={e => {
                      setForm({ ...form, address: e.target.value.trimStart() })
                      ticketData.formAddress = e.target.value.trimStart()
                    }}
                    onBlur={() => addressValidation()}
                    defaultValue={ticketData.formAddress}
                  />
                  {/* バリデーションエラー文言 */}
                  <small className={styles.validationErrorText}>
                    {validationError.addressError}
                  </small>
                </>
              )}
              {/* 入力内容の確認 */}
              {confirmFlag && (
                <p className={styles.inputConfirm}>{ticketData.formAddress}</p>
              )}
            </div>
          </div>
        </div>

        {/* ボタン */}
        <div>
          {/* 入力内容の確認へ進む */}
          <div className={styles.linkWrap} style={{ marginTop: '20px' }}>
            <Button
              text={confirmFlag ? confirmText : '入力内容の確認へ進む'}
              type="toNext"
              // どれかにエラーが表示されている or 値が未入力の場合は非活性
              disabled={
                !form.name ||
                !!validationError.nameError ||
                !form.tel ||
                !!validationError.telError ||
                !form.mail ||
                !!validationError.mailError ||
                !form.confirmationMail ||
                !!validationError.confirmationMailError ||
                !form.postalCode ||
                !!validationError.postalCodeError ||
                !form.address ||
                !!validationError.addressError
              }
              onClick={confirmFlag ? payment : confirmation}
            />
          </div>
          {/* 前の画面に戻る or 入力内容を修正する */}
          <div className={`${styles.linkWrap} ${styles.backButton}`}>
            <Button
              text={confirmFlag ? '入力内容を修正する' : '前の画面に戻る'}
              onClick={confirmFlag ? inputFix : backPage}
              siteCode={0}
            />
          </div>
        </div>
      </>
    )
  }

  return (
    <div className={styles.pageWrap}>
      <header className={styles.header}>
        {!ticketData.isDirect && (
          <button className={styles.toBack} onClick={() => backPage()}>
            <img src={back} />
          </button>
        )}
        <h1 className={styles.title}>情報入力</h1>
      </header>
      <div className={styles.mainContents} id="contentAreaPersonalData">
        {/* 入力フォーム & ボタン */}
        <PersonalDataForm />
      </div>
    </div>
  )
}
