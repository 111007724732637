import React from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

export const ListType = {
  general: 0,
  ticket: 1,
  total: 2,
  linethrough: 3,
  telasaTotal: 4,
}

export type ListTicketAmountProps = {
  type: number
  text: string
  amount: number
  className?: string
}

export function ListTicketAmount({
  type,
  text,
  amount,
  className,
}: ListTicketAmountProps): JSX.Element {
  const wrapClassName = clsx(styles.listWrap, {
    [styles['type-total']]:
      type === ListType.total || type === ListType.telasaTotal,
    [styles['not-border']]: className === 'not-border',
  })
  const titleClassName = clsx(styles.labelTitle, {
    [styles['type-ticket']]: type === ListType.ticket,
  })
  const amountClassName = clsx(styles.amount, {
    [styles['type-linethrough']]: type === ListType.linethrough,
  })
  const preAmount = `${amount.toLocaleString()}円`

  return (
    <>
      <li className={wrapClassName}>
        <span className={titleClassName}>{text}</span>
        <span className={amountClassName}>{preAmount}</span>
      </li>
      {type === ListType.telasaTotal && (
        <p className={styles.ponta}>※ Pontaポイントは付与されません</p>
      )}
    </>
  )
}
