import React from 'react'
import logo from '@/assets/images/logo.svg'
import jasrac from '@/assets/images/jasrac.jpg'
import nextone from '@/assets/images/nextone.png'
import styles from './styles.module.scss'

export function Footer(): JSX.Element {
  return (
    <footer className={styles.footer}>
      <img src={logo} alt="uP!!!" />
      <div className={styles.ulWrap}>
        <ul>
          <li>
            <a href={process.env.REACT_APP_TOP_PAGE_URL}>ホーム</a>
          </li>
          <li>
            <a
              href={
                (process.env.REACT_APP_TOP_PAGE_URL ?? '') + '/notification/'
              }
            >
              お知らせ
            </a>
          </li>
          <li>
            <a href={(process.env.REACT_APP_BASE_URL ?? '') + '/mypage/'}>
              マイページ
            </a>
          </li>
          <li>
            <a href={(process.env.REACT_APP_TOP_PAGE_URL ?? '') + '/help/'}>
              ヘルプ
            </a>
          </li>
        </ul>
        <ul>
          <li>
            <a href={(process.env.REACT_APP_TOP_PAGE_URL ?? '') + '/term/'}>
              利用規約
            </a>
          </li>
          <li>
            <a
              href={
                (process.env.REACT_APP_TOP_PAGE_URL ?? '') + '/privacypolicy/'
              }
            >
              プライバシーポリシー
            </a>
          </li>
          <li>
            <a
              href={
                (process.env.REACT_APP_TOP_PAGE_URL ?? '') +
                '/about-transactions/'
              }
            >
              特定商取引法に基づく表記
            </a>
          </li>
          <li>
            <a href="https://www.kddi.com/terms/requirements/">
              Cookie情報の利用、広告配信等について
            </a>
          </li>
        </ul>
      </div>
      <p className={styles.copyright}></p>
      <div className={styles.license}>
        <img src={jasrac} />
        <div>
          <p>許諾番号</p>
          <p>9007016373Y45037</p>
          <p>9007016374Y45040</p>
        </div>
        <img src={nextone} />
        <div>
          <p>許諾番号</p>
          <p>ID000007376</p>
          <p>ID000007377</p>
        </div>
      </div>
    </footer>
  )
}
