import React, { useCallback } from 'react'
import clsx from 'clsx'
import IconError from '@/assets/icon/icn-error.svg'
import { Button } from '@/components/parts/Button'
import styles from './styles.module.scss'

const ScreenMode = {
  dark: styles.dark,
  light: styles.light,
} as const

export type ErrorProps = {
  title?: string
  code?: string
  message?: string
  buttonTitle?: string
  link?: string
  mode?: keyof typeof ScreenMode
}

export function Error({
  title = 'エラーが発生しました',
  code,
  message,
  buttonTitle = 'TOPページへ戻る',
  link = process.env.REACT_APP_TOP_PAGE_URL ?? '',
  mode = 'dark',
}: ErrorProps): JSX.Element {
  const openLinkPage = useCallback(() => {
    window.open(link, '_parent', 'noreferrer')
  }, [link])

  return (
    <div className={clsx(styles.contentsWrap, ScreenMode[mode])}>
      <div className={styles.iconWrap}>
        <img className={styles.icn} src={IconError} />
      </div>
      <p className={styles.message1}>{title}</p>
      {code !== undefined && (
        <p className={styles.code}>（エラーコード：{code}）</p>
      )}
      <small className={styles.message2}>{message}</small>
      <div className={styles.linkWrap}>
        <Button
          text={buttonTitle}
          onClick={() => {
            openLinkPage()
          }}
        />
      </div>
    </div>
  )
}
