import React from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import { noOperation } from '@/helpers/utils'

export const ListType = {
  premium: 'premium',
  normal: 'normal',
}

export type ListTicketProps<T> = {
  type: string
  text: string
  disabled?: boolean
  value?: T
  onClick?: (value?: T) => void
}

export function ListMovieLink<T>({
  type,
  text,
  disabled,
  value,
  onClick = noOperation,
}: ListTicketProps<T>): JSX.Element {
  const titleClassName = clsx(styles.labelTitle, {
    [styles['type-premium']]: type === 'premium',
    [styles['type-disabled']]: disabled,
  })
  return (
    <li className={styles.listWrap}>
      <button
        onClick={() => {
          onClick(value)
        }}
        className={titleClassName}
      >
        <span className={titleClassName}>{text}</span>
        <svg
          className={styles.toNext}
          width="8"
          height="12"
          viewBox="0 0 8 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.60241 6.93542L2.9253 11.6125C2.40869 12.1292 1.57106 12.1292 1.05445 11.6125C0.537839 11.0959 0.537839 10.2583 1.05445 9.74169L4.79614 6L1.05445 2.25831C0.537839 1.74169 0.537839 0.904072 1.05445 0.387459C1.57106 -0.129153 2.40869 -0.129153 2.9253 0.387459L7.60241 5.06458C8.11903 5.58119 8.11903 6.41881 7.60241 6.93542Z"
            fill="currentColor"
          />
        </svg>
      </button>
    </li>
  )
}
