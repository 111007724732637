import React, { createContext, ReactNode } from 'react'
import { generateRandomNumber } from '@/helpers/utils'
import styles from './styles.module.scss'

/**
 * 子要素のRadioに
 * - Radio-group化するためのIDを提供する。
 * - groupの持つデータを伝播する。
 * - Radioの選択イベントをハンドリングする。
 */
export type RadioGroupContextProps<T> = {
  /**
   * 子のRadioのname属性にセットすることで、group化するために使用する。
   */
  id: string
  /**
   * チェックボックスグループのラベル。
   * wai-aria用。
   */
  groupLabelId: string
  /**
   * 現在のcheck状態のvalueの配列。
   */
  value: T

  /**
   * チェックボックスの選択状態を変更するリクエストを受け付けるイベントハンドラ。
   */
  onChange: (value: T) => void
}

export const RadioGroupContext = createContext<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  RadioGroupContextProps<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
>({} as RadioGroupContextProps<any>)

type RadioGroupProps<T> = {
  /**
   * 任意の子要素を配置可能。
   * 配置される要素がチェックボックスの場合、単一のチェックボックスグループとなる。
   */
  children?: ReactNode
  /**
   * 設定された値を持つ子要素のRadioがある場合、選択状態になる。
   */
  value: T
  /**
   * チェックボックスの値変更イベントを処理するためのハンドラ。
   * 通常、イベントのvalue値をgroupの状態に反映すれば良い。
   */
  onChange: (value: T) => void
  /**
   * グループラベルの使用
   */
  groupLabel?: boolean
}

/**
 * ## チェックボックスグループ
 * childrenとしてセットされたRadioコンポーネントをグループ化する。
 */
export function RadioGroup<T>({
  children,
  value,
  onChange,
  groupLabel = false,
}: RadioGroupProps<T>): JSX.Element {
  const id = generateRandomNumber(5)
  const groupLabelId = generateRandomNumber(5)
  const ariaLabelledby = groupLabel ? groupLabelId : undefined
  return (
    <RadioGroupContext.Provider value={{ id, value, onChange, groupLabelId }}>
      <div
        className={styles.RadioGroupWrap}
        aria-checked="false"
        aria-labelledby={ariaLabelledby}
        data-testid="RadioGroup"
        role="Radio"
      >
        {children}
      </div>
    </RadioGroupContext.Provider>
  )
}
