import React from 'react'
import { Header } from '@/components/parts/Header'
import { Footer } from '@/components/parts/Footer'
import { Error } from '@/components/parts/Error'
import styles from './styles.module.scss'

export function CommonError(): JSX.Element {
  return (
    <div className={styles.pageWrapper}>
      <Header />
      <div className={styles.contentsWrap}>
        <Error />
      </div>
      <Footer />
    </div>
  )
}
