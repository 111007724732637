import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useState,
  useContext,
} from 'react'

export type ModalContextProps = {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  modalContent: ReactNode
  setModalContent: Dispatch<SetStateAction<ReactNode>>
  openModal: (node: ReactNode) => void
  closeModal: () => void
}

export const ModalContext = createContext({} as ModalContextProps)

export const useProvideModal = (): ModalContextProps => {
  const [isOpen, setIsOpen] = useState(false)
  const [modalContent, setModalContent] = useState<ReactNode>(null)

  const openModal = useCallback((node: ReactNode) => {
    setModalContent(node)
    setIsOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setModalContent(null)
    setIsOpen(false)
  }, [])

  return {
    isOpen,
    setIsOpen,
    modalContent,
    setModalContent,
    openModal,
    closeModal,
  }
}

export const useModal = (): ModalContextProps => {
  return useContext(ModalContext)
}
