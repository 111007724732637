import type { UnionToIntersection } from 'type-fest'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import minMax from 'dayjs/plugin/minMax'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

/* eslint-disable import/no-named-as-default-member */
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(isBetween)
dayjs.extend(minMax)
/* eslint-enable import/no-named-as-default-member */
dayjs.tz.setDefault('Asia/Tokyo')

/**
 * 対象オブジェクトのkeyからなるUnionを返す。
 */
export type ObjectKeyType<T> = keyof T

/**
 * 対象オブジェクトの値からなるUnionを返す。
 */
export type ObjectValueType<T> = T[ObjectKeyType<T>]

/**
 * 入力型のkeyを元に、{ [key]: key } となるようなオブジェクト型を返す。
 */
export type KeyObject<T> = UnionToIntersection<
  keyof T extends infer U
    ? U extends string | symbol | number
      ? { [K in U]: K }
      : never
    : never
>

/**
 * 入力オブジェクトのkeyを元に、{ [key]: key } となるようなオブジェクトを返す。
 * 文字リテラルによる入力を避けるために使用する。
 */
export const keyObject = <T>(value: T): KeyObject<T> =>
  Object.keys(value).reduce(
    (prev, nextValue) => ({ ...prev, [nextValue]: nextValue }),
    {} as Partial<KeyObject<T>>,
  ) as KeyObject<T>

/**
 * 何も実行しない関数。イベントハンドラの初期値として使用する。
 */
export const noOperation = (): void => {
  // do nothing.
}

/**
 * ランダムな数字のみの文字列を生成する。
 * @param digit - 桁数
 * @returns
 */
export const generateRandomNumber = (digit: number): string => {
  const n = Math.floor(
    10 ** (digit - 1) + Math.random() * 9 * 10 ** (digit - 1),
  )
  return String(n) + ''
}

/**
 * 日付をフォーマットする
 * @param dateTime
 * @returns
 */
export const dayFormat = (dateTime: string): string => {
  const formatDay = dayjs(dateTime).tz().format('YYYY/M/D')
  return formatDay
}

export const dateTimeFormat = (dateTime: string): string => {
  return dayjs(dateTime).tz().format('YYYY/M/D H:mm')
}

/**
 * 日付のbefore,afterのbooleanを返す
 * @param dateTime
 * @returns
 */
export const isBeforeNow = (dateTime: string): boolean => {
  const now = dayjs()
  return dayjs(dateTime).isBefore(now)
}

export const isAfterNow = (dateTime: string): boolean => {
  const now = dayjs()
  return dayjs(dateTime).isAfter(now)
}

/**
 * 日付の範囲内かbooleanを返す
 * @param start
 * @param end
 * @returns
 */
export const isBetweenDate = (start: string, end: string): boolean => {
  const now = dayjs()
  return now.isBetween(dayjs(start), dayjs(end))
}

export const maxDate = (dates: string[]): string => {
  if (!dates.length) {
    return ''
  }

  const days = dates.map(date => {
    return dayjs(date)
  })

  return dayjs.max(days).tz().format('YYYY/M/D H:mm')
}

export const minDate = (dates: string[]): string => {
  if (!dates.length) {
    return ''
  }

  const days = dates.map(date => {
    return dayjs(date)
  })

  return dayjs.min(days).tz().format('YYYY/M/D H:mm')
}
